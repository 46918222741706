/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { updateSchedule } from '../api/UserAPI';
import { updateSites, validateTime } from './Update';
import ScheduleTimePicker from './ScheduleTimePicker';
import Exceptions from './Exceptions';
import EnableDisableNotification from './EnableDisableNotification';

function SettingsTab() {
  const dispatch = useDispatch();
  const sites = useSelector((state) => state.userInfo.sites);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const selectedSiteName = useSelector((state) => state.userInfo.selectedSiteName);
  const [exList, setExList] = useState([]);
  const [changesMade, setChangesMade] = useState();
  const [showError, setShowError] = useState(false);
  const [time1, setTime1] = useState(null);
  const [time2, setTime2] = useState(null);
  const [cooldownTime, setCooldownTime] = useState(0);
  const [settingsCity, setSettingsCity] = useState({
    chosenName: '',
    chosenId: '',
  });
  const [state, setState] = useState({
    monStartTime: '',
    monEndTime: '',
    tuesStartTime: '',
    tuesEndTime: '',
    wedStartTime: '',
    wedEndTime: '',
    thursStartTime: '',
    thursEndTime: '',
    friStartTime: '',
    friEndTime: '',
    satStartTime: '',
    satEndTime: '',
    sunStartTime: '',
    sunEndTime: '',
  });

  useEffect(() => {
    setChangesMade(false);

    if (sites) {
      const found = sites?.find((site) => site.siteId === settingsCity.chosenId);

      if (found?.schedule) {
        // create object for hours of operation
        const entries = Object.entries(found?.schedule?.hoursOfOperation);

        for (const [dayOfWeek, timeSlots] of entries) {
          if (dayOfWeek === 'monday') {
            setState((prev) => ({ ...prev, monStartTime: timeSlots?.timeSlots[0]?.startTime ?? '' }));
            setState((prev) => ({ ...prev, monEndTime: timeSlots?.timeSlots[0]?.endTime ?? '' }));
          } else if (dayOfWeek === 'tuesday') {
            setState((prev) => ({ ...prev, tuesStartTime: timeSlots?.timeSlots[0]?.startTime ?? '' }));
            setState((prev) => ({ ...prev, tuesEndTime: timeSlots?.timeSlots[0]?.endTime ?? '' }));
          } else if (dayOfWeek === 'wednesday') {
            setState((prev) => ({ ...prev, wedStartTime: timeSlots?.timeSlots[0]?.startTime ?? '' }));
            setState((prev) => ({ ...prev, wedEndTime: timeSlots?.timeSlots[0]?.endTime ?? '' }));
          } else if (dayOfWeek === 'thursday') {
            setState((prev) => ({ ...prev, thursStartTime: timeSlots?.timeSlots[0]?.startTime ?? '' }));
            setState((prev) => ({ ...prev, thursEndTime: timeSlots?.timeSlots[0]?.endTime ?? '' }));
          } else if (dayOfWeek === 'friday') {
            setState((prev) => ({ ...prev, friStartTime: timeSlots?.timeSlots[0]?.startTime ?? '' }));
            setState((prev) => ({ ...prev, friEndTime: timeSlots?.timeSlots[0]?.endTime ?? '' }));
          } else if (dayOfWeek === 'saturday') {
            setState((prev) => ({ ...prev, satStartTime: timeSlots?.timeSlots[0]?.startTime ?? '' }));
            setState((prev) => ({ ...prev, satEndTime: timeSlots?.timeSlots[0]?.endTime ?? '' }));
          } else if (dayOfWeek === 'sunday') {
            setState((prev) => ({ ...prev, sunStartTime: timeSlots?.timeSlots[0]?.startTime ?? '' }));
            setState((prev) => ({ ...prev, sunEndTime: timeSlots?.timeSlots[0]?.endTime ?? '' }));
          }
        }

        setCooldownTime(found?.notification?.cooldown?.duration / 60);
        

        // create list for exceptions
        const list = found?.schedule?.exceptions;
        setExList(list);
      } else {
        const initialState = {
          monStartTime: '',
          monEndTime: '',
          tuesStartTime: '',
          tuesEndTime: '',
          wedStartTime: '',
          wedEndTime: '',
          thursStartTime: '',
          thursEndTime: '',
          friStartTime: '',
          friEndTime: '',
          satStartTime: '',
          satEndTime: '',
          sunStartTime: '',
          sunEndTime: '',
        };
        setState(initialState);
        setExList([]);
      }
      setSettingsCity({ chosenName: selectedSiteName, chosenId: selectedSiteId });
    }
  }, [settingsCity.chosenId, sites]);

  const handleChange = (newValue, name, compareName) => {
    setTime1(name);
    setTime2(compareName);
    const value = moment(newValue._d).format('HH:mm');
    setChangesMade(true);
    setState({
      ...state,
      [name]: value,
    });
  };

  useEffect(() => {
    if (time1 && time2) {
      if (time1.includes('tart')) {
        if (validateTime(state[time1], state[time2])) setShowError(false);
        else { 
          setShowError(true);
          setChangesMade(false);
         }
      } else if (validateTime(state[time2], state[time1])) setShowError(false);
      else {
        setShowError(true);
        setChangesMade(false);
      }
    }
  }, [state, time1, time2]);

  const updateCooldownTime = (e) => {
    setChangesMade(true);
    setCooldownTime(e.target.value);
  }

  const saveSchedule = async () => {
    const hoursObject = {
      monday: {
        timeSlots: [{
          startTime: state.monStartTime,
          endTime: state.monEndTime,
        }],
      },
      tuesday: {
        timeSlots: [{
          startTime: state.tuesStartTime,
          endTime: state.tuesEndTime,
        }],
      },
      wednesday: {
        timeSlots: [{
          startTime: state.wedStartTime,
          endTime: state.wedEndTime,
        }],
      },
      thursday: {
        timeSlots: [{
          startTime: state.thursStartTime,
          endTime: state.thursEndTime,
        }],
      },
      friday: {
        timeSlots: [{
          startTime: state.friStartTime,
          endTime: state.friEndTime,
        }],
      },
      saturday: {
        timeSlots: [{
          startTime: state.satStartTime,
          endTime: state.satEndTime,
        }],
      },
      sunday: {
        timeSlots: [{
          startTime: state.sunStartTime,
          endTime: state.sunEndTime,
        }],
      },
    };

    const response = await updateSchedule(settingsCity?.chosenId, hoursObject, exList, Number(cooldownTime) * 60);
    if (typeof (response) === 'object') {
      await updateSites(dispatch);
      setChangesMade(false);
    } else {
      console.log('ERROR');
    }
  };

  // ------------------------------------------------------------------

  return (
    <div id="settings-popup" className="content">
      <div className="sub-header dash-tab settings-header  mb-1">
        <div className="left">
          <h1>Settings</h1>
        </div>
      </div>
      <EnableDisableNotification />
      <div className="schedule-content">
        <div>
          <h3 className="black mb-05">Property Viewing Schedule</h3>
          <p className="mb-05">Viewing hours: events generated outside of these hours will trigger alerts.</p>
          <p className="mb-1">Click to edit hours.</p>
        </div>
        <div className="schedule-table">
          <table className="mb-1">
            <thead>
              <tr>
                <th>Day</th>
                <th>Start Time</th>
                <th>End Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Monday</th>
                <td><ScheduleTimePicker value={state.monStartTime} onChange={(value) => handleChange(value, 'monStartTime', 'monEndTime')} /></td>
                <td><ScheduleTimePicker value={state.monEndTime} onChange={(value) => handleChange(value, 'monEndTime', 'monStartTime')} /></td>
              </tr>
              <tr>
                <th>Tuesday</th>
                <td><ScheduleTimePicker value={state.tuesStartTime} onChange={(value) => handleChange(value, 'tuesStartTime', 'tuesEndTime')} /></td>
                <td><ScheduleTimePicker value={state.tuesEndTime} onChange={(value) => handleChange(value, 'tuesEndTime', 'tuesStartTime')} /></td>
              </tr>
              <tr>
                <th>Wednesday</th>
                <td><ScheduleTimePicker value={state.wedStartTime} onChange={(value) => handleChange(value, 'wedStartTime', 'wedEndTime')} /></td>
                <td><ScheduleTimePicker value={state.wedEndTime} onChange={(value) => handleChange(value, 'wedEndTime', 'wedStartTime')} /></td>
              </tr>
              <tr>
                <th>Thursday</th>
                <td><ScheduleTimePicker value={state.thursStartTime} onChange={(value) => handleChange(value, 'thursStartTime', 'thursEndTime')} /></td>
                <td><ScheduleTimePicker value={state.thursEndTime} onChange={(value) => handleChange(value, 'thursEndTime', 'thursStartTime')} /></td>
              </tr>
              <tr>
                <th>Friday</th>
                <td><ScheduleTimePicker value={state.friStartTime} onChange={(value) => handleChange(value, 'friStartTime', 'friEndTime')} /></td>
                <td><ScheduleTimePicker value={state.friEndTime} onChange={(value) => handleChange(value, 'friEndTime', 'friStartTime')} /></td>
              </tr>
              <tr>
                <th>Saturday</th>
                <td><ScheduleTimePicker value={state.satStartTime} onChange={(value) => handleChange(value, 'satStartTime', 'satEndTime')} /></td>
                <td><ScheduleTimePicker value={state.satEndTime} onChange={(value) => handleChange(value, 'satEndTime', 'satStartTime')} /></td>
              </tr>
              <tr>
                <th>Sunday</th>
                <td><ScheduleTimePicker value={state.sunStartTime} onChange={(value) => handleChange(value, 'sunStartTime', 'sunEndTime')} /></td>
                <td><ScheduleTimePicker value={state.sunEndTime} onChange={(value) => handleChange(value, 'sunEndTime', 'sunStartTime')} /></td>
              </tr>
            </tbody>
          </table>
          { showError && <p className="error">Error: End time should be after start time</p> }
          <Exceptions setChangesMade={setChangesMade} exList={exList} setExList={setExList} />
        </div>
        <div>
          <h3 className="black mb-05">Motion Alert Cooldown</h3>
          <p className="mb-05" style={{ textAlign:'justify'}}>Once a motion-based alert is generated by a motion sensor or camera, it will not generate an additional
               alert until a motion event has not been detected for the period of time defined below.  
               Motion events will still be captured, but will go directly to the event log without sending an Alert via email or SMS.</p>
          <input type="number" min="0" value={cooldownTime} className="cooldown-input" onChange={(e) => updateCooldownTime(e)}/>
          <span>minutes</span>
        </div>

        <button className="primary-btn save-btn" onClick={saveSchedule} disabled={!changesMade}>Save</button>
      </div>
    </div>
  );
}

export default SettingsTab;
