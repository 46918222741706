/* eslint-disable */
import aqi from '../assets/aqi.svg';
import co2 from '../assets/co2.svg';
import guideLight from '../assets/guide-light.svg';
import motion from '../assets/motion.svg';
import pm from '../assets/pm.svg';
import powerOut from '../assets/power-out-light.svg';
import tempHumiMotion from '../assets/temp-humi-motion.svg';
import tempHumi from '../assets/temp-humi.svg';
import usb from '../assets/usb.svg';
import video from '../assets/video-camera.svg';
import warning from '../assets/warning.svg';
import water from '../assets/water.svg';
import proximity from '../assets/proximity-sensor.svg';

function DisplayTileIcon(props) {
  const icon = props?.icon;
  return (
    icon === 'alert'
      ? <img src={warning} alt="warning" />
      : icon === 'video'
        ? <img src={video} alt="video camera" />
        : icon === 'motion'
          ? <img src={motion} alt="motion sensor" />
          : icon === 'tempHumi'
            ? <img src={tempHumi} alt="temp humi" />
            : icon === 'tempHumiMotion'
              ? <img src={tempHumiMotion} alt="temp humi motion" />
              : icon === 'powerOut'
                ? <img src={powerOut} alt="power out light" />
                : icon === 'aq'
                  ? <img src={aqi} alt="air quality" />
                  : icon === 'guideLight'
                    ? <img src={guideLight} alt="guide light" />
                    : icon === 'usb'
                      ? <img src={usb} alt="usb" />
                      : icon === 'pm'
                        ? <img src={pm} alt="particulate matter" />
                        : icon === 'water'
                          ? <img src={water} alt="water" />
                          : icon === 'co2'
                            ? <img src={co2} alt="CO2" />
                            : icon === 'proximity'
                              ? <img src={proximity} alt="Proximity-Sensor"/>
                                : null
  );
}

export default DisplayTileIcon;
