/* eslint-disable */
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { formatDate, formatSource, formatTime } from './FormatStuff';
import comment from '../assets/comment.svg';
import archive from '../assets/archive.svg';
import download from '../assets/download.svg';
import unread from '../assets/unread.svg';
import read from '../assets/read.svg';
import select from '../assets/select.svg';
import filter from '../assets/filter.svg';
import tag from '../assets/tag.svg';
import leftAffrow from '../assets/left-arrow.svg';
import { patchEvent, updateThreatLevel } from '../api/UserAPI';
import { updateAlertsIncrementally, archiveDirectly, archiveSelectedEvents, updateEventsIncrementally, updateEvents } from './Update';
import { updateCommentList, updateCommentType, updateViewEventPopup } from '../redux/userInfo';
import { downloadCSV } from '../utils/utils';
import EventsFilter from './EventsFilter';
import Popup from './Popup';
import AlertsFilter from './AlertsFilter';
import moment from 'moment-timezone';
import CommentPopup from './CommentPopup';

function EventsTable(props) {
  const dispatch = useDispatch();
  const alertsOrder = useSelector((state) => state.userInfo.alertsOrder);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const selectedRoomId = useSelector((state) => state.userInfo.selectedRoomId);
  const activeList = useSelector((state) => state.userInfo.activeList);
  const [rightClick, setRightClick] = useState(false);
  const [contextBoxPoints, setContextBoxPoints] = useState({ x: 0, y: 0 });
  const [targetAlert, setTargetAlert] = useState(null);
  const sites = useSelector((state) => state.userInfo.sites);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [checkAll, setCheckAll] = useState(false);
  const [targetTable, setTargetTable] = useState(null);
  const [ selectState, setSelectState ] = useState(false);
  const [ showCheckbox, setShowCheckbox ] = useState(false);
  const [showThreat, setShowThreat] = useState(false);
  const [displayedEvents, setDisplayedEvents] = useState(props.list);
  const [newEvent, setNewEvent] = useState();
  const [selectedTagFilter, setSelectedTagFilter] = useState('All');
  const [showPopup, setShowPopup] = useState(false);
  const [startTimestamp, setStartTimestamp] = useState(null);
  const [endTimestamp, setEndTimestamp] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startDateObject, setStartDateObject] = useState(null);
  const [endDateObject, setEndDateObject] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const tagsMenu = useRef();
  // const tagButton = useRef();

  const [buttonsState, setButtonsState] = useState({
    read: !selectState,
    unread: !selectState,
    archive: !selectState,
    download: !selectState,
    flag: !selectState,
  })
  const selectedSite = sites ? sites.find((site) => site.siteId === selectedSiteId) : {};
  const timeZone = selectedSite?.config?.tz?.id;

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (startTime && startDate) setStartDateObject(moment.tz(`${moment(startDate.$d).format('MM-DD-YYYY')} ${moment(startTime._d).format('HH:mm')}`, 'MM-DD-YYYY HH:mm', timeZone));
    else if (startDate) setStartDateObject(moment.tz(`${moment(startDate.$d).format('MM-DD-YYYY')}`, 'MM-DD-YYYY HH:mm', timeZone));
    // eslint-disable-next-line no-underscore-dangle
    if (endTime && endDate) setEndDateObject(moment.tz(`${moment(endDate.$d).format('MM-DD-YYYY')} ${moment(endTime._d).format('HH:mm')}`, 'MM-DD-YYYY HH:mm', timeZone));
    else if (endDate) setEndDateObject(moment.tz(`${moment(endDate.$d).format('MM-DD-YYYY')}`, 'MM-DD-YYYY HH:mm', timeZone));
  }, [startDate, startTime, endDate, endTime]);

  useEffect(() => {
    const startDateNotSet = Number.isNaN(moment(startDateObject).valueOf());
    const endDateNotSet = Number.isNaN(moment(endDateObject).valueOf());
    if ((startDateNotSet && !endDateNotSet) || (!startDateNotSet && endDateNotSet)) {
      setErrorMessage('You must enter start and end dates');
    } else if (moment(startDateObject).valueOf() > moment(endDateObject).valueOf()) setErrorMessage('start date must be before end date');
    else {
      if (props.insideLog) {
        setErrorMessage('');
        props?.setLogFilterStartTime(moment(startDateObject).valueOf());
        props?.setLogFilterEndTime(moment(endDateObject).valueOf());
        setStartTimestamp(moment(startDateObject).valueOf());
        setEndTimestamp(moment(endDateObject).valueOf());
      }
    }
  }, [startDateObject, endDateObject]);

  useEffect(() => {
    setDisplayedEvents(props.list);
  }, [props.list]);

  const updateAlerts = async () =>  await updateAlertsIncrementally(
    dispatch,
    selectedSiteId,
    selectedRoomId,
    25,
    '',
    [],
    alertsOrder,
  );
  useEffect(() => {
   updateAlerts();
  },[selectedTagFilter])

  const requestLogsWithFilter = async () => {
    if (props.insideLog && selectedRoomId) {
      props.setEventLoading(true);
      await updateEvents(dispatch, selectedSiteId, selectedRoomId, 25, startTimestamp, endTimestamp, props.sourceType, props.selectedOptionLogIndex === 0 ? 'desc' : 'asc');
      props.setEventLoading(false);
    }
  }

  useEffect(() => {
    setSelectedEvents([]);
    setCheckAll(false);
    if (targetTable) targetTable.querySelectorAll('.events-checkbox').forEach( el => el.checked = false );
  },[startTimestamp, endTimestamp, props.sourceType, selectedTagFilter])

  useEffect(() => {
    requestLogsWithFilter();
  },[startTimestamp, endTimestamp, props.selectedOptionLogIndex, props.sourceType]);

  const handleScroll = async (e) => {
    let newEvents;
    if (e.target.scrollTop < 500) {
      if (props.updateScrollState)  props.updateScrollState(false)
    }
    const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 1;
    if (bottom) {
      if (!props.list?.length) return;
      props.insideLog ? props.setEventLoading(true) : props.setAlertLoading(true);
      if (props.updateScrollState) props.updateScrollState(true);
      try {
        newEvents = 
        props.insideLog
          ?
            await updateEventsIncrementally(dispatch, selectedSiteId, selectedRoomId, 25, props.list[props.list.length - 1]?.eventId, props.list, startTimestamp, endTimestamp, props.sourceType, props.selectedOptionLogIndex === 0 ? 'desc' : 'asc')
          : await updateAlertsIncrementally(
            dispatch,
            selectedSiteId,
            selectedRoomId,
            25,
            props.list[props.list.length - 1]?.eventId,
            props.list,
            alertsOrder,
          );
      } catch (err) {
        console.log(err);
      }
      props.insideLog ? props.setEventLoading(false) : props.setAlertLoading(false);
    }
    if(checkAll) applySelectAll(newEvents ?? []);
  };

  // when alert is read - set status to UNRESOLVED
  const readEvent = async (event) => {
    props.setSelectedEvent(event);
    setRightClick(false);
    dispatch(updateViewEventPopup(true));
    if (!props.insideLog) {
      const updatedEvent = await patchEvent(selectedSiteId, event.eventId, 'UNRESOLVED');
      if (updatedEvent?.status === 'UNRESOLVED') {
        setNewEvent(updatedEvent);
      } else {
        console.log('event could not be read');
      }
    }
    if (event?.comments?.length > 0) {
      for (const comment of event?.comments) {
        dispatch(updateCommentList(comment));
      }
    }
  };

  // open comment popup
  const openComments = (event, alert) => {
    setSelectedEvent(alert);
    if (alert?.comments?.length > 0) {
      for (const comment of alert?.comments) {
        dispatch(updateCommentList(comment));
      }
    }
    dispatch(updateCommentType('alert'));
    setShowComments(true);
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const showMenu = (event, alert) => {
    event.preventDefault();
    setRightClick(true);
    setContextBoxPoints({
      x: event.pageX,
      y: event.pageY,
    });
    setTargetAlert(alert);
  };

  const convertToUnread = async (event) => {
    const updatedEvent = await patchEvent(selectedSiteId, event.eventId, 'NEW');
    if (updatedEvent?.status === 'NEW') {
      setNewEvent(updatedEvent);
    } else {
      console.log('event could not be changed to new');
    }
    setRightClick(false);
  };

  const handleCheckbox = (event, alert) => {
    if (event.target.checked) setSelectedEvents( prevState => [...prevState, alert])
    else {
      setSelectedEvents(prevState => prevState.filter((event) => event.eventId !== alert.eventId))
  }
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  }

  const downloadSelectedEvents = () => {
   let data = 'timestamp,siteId,siteName,roomId,roomName,deviceId,deviceName,message,type,content,status,source,tag,commentedBy,comment,commentedById\r\n';
   selectedEvents.forEach((event) => {
    data += `${event?.eventId ? `${formatDate(event?.eventId, timeZone)} ${formatTime(event?.eventId, timeZone)},` : ','}`;
    data += `${event?.content?.siteId ? `${event?.content?.siteId},` : ',' }`;
    data += `${event?.content?.siteName ? `"${event?.content?.siteName}",` : ',' }`;
    data += `${event?.content?.roomId ? `${event?.content?.roomId},` : ',' }`;
    data += `${event?.content?.roomName ? `"${event?.content?.roomName}",` : ','}`;
    data += `${event?.content?.deviceId ? `${event?.content?.deviceId},` : ','}`;
    data += `${event?.content?.deviceName ? `"${event?.content?.deviceName}",`: ','}`;
    data += `${event?.content?.message ? `"${event?.content?.message}",` : ','}`;
    data += `${event?.type ? `${event?.type},` : ',' }`;
    data += `${event.source === 'video' ? `duration:${event.content.duration};start:${event.content.start};streamName:${event.content.streamName},` : ','}`;
    data += `${event?.status ? `${event.status},` : ','}`;
    data += `${event?.source ? `${event.source},` : ','}`;
    data += `${event?.threatLevel ? `${event.threatLevel},`: ','}`
    if (event?.comments.length > 0) {
      data += ',,\r\n';
      event?.comments.forEach((comment) => {
        data += `${formatDate(comment?.commentedAt)} ${formatTime(comment?.commentedAt)},,,,,,,,,,,,`;
        data += `${comment?.commentedBy},`;
        data += `${comment?.comment},`;
        data += `${comment?.commentedById},`;
        data += '\r\n';
      })
   
    } else {
      data += ',,,\r\n'
    }
   })
  downloadCSV(data);
  setSelectedEvents([]);
  targetTable.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );
  setCheckAll(false);
  };

  const selectAll = async() => {
    if (!checkAll)  {
      if (selectedTagFilter === 'All')
        setSelectedEvents(props.list);
      else {
        const tempArry = props.list.filter((event) => event.threatLevel && event?.threatLevel.toLowerCase() === selectedTagFilter.toLowerCase());
        setSelectedEvents(tempArry);
    }
    }
    else setSelectedEvents([]);
    setCheckAll(!checkAll);
    targetTable.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = !checkAll );
  };

  const  applySelectAll = (list) => {
    for (const event of list) {
      // there are two elements one for the small table and one for the wide table
      targetTable.querySelectorAll(`#cb-${event.eventId}`).forEach( el => el.checked = checkAll );
    }
      setSelectedEvents(prevState => [...prevState, ...list]);
      if (selectedTagFilter !== 'All') {
        const tempArry = selectedEvents.filter((event) => event.threatLevel && event?.threatLevel.toLowerCase() === selectedTagFilter.toLowerCase());
        setSelectedEvents(tempArry);
      }
  };

  const archiveEvents = (e) => {
    archiveSelectedEvents(dispatch, e, selectedEvents, selectedSiteId, null, alertsOrder);
    setCheckAll(false);
    targetTable.querySelector('.events-checkbox').checked = false;
  };

  useEffect(() => {
    if (selectedEvents.length < 1) {
      setCheckAll(false);
      if (targetTable && showCheckbox && displayedEvents.length > 0) targetTable.querySelector('#select-all-cb').checked = false;
    }
  },[selectedEvents]);

  const handleSelectClick = () => {
    setButtonsState( {read: selectState,
      unread: selectState,
      archive: selectState,
      download: selectState,
      flag: selectState,
    });
    setShowCheckbox(!selectState);
    setSelectState(!selectState);
  };

  const convertSelectedToRead = () => {
    const convertPromises = selectedEvents.map( async(event) => {
      return new Promise (async(resolve) => {
        props.insideLog ? props.setEventLoading(true) : props.setAlertLoading(true);
        const response = await patchEvent(selectedSiteId, event.eventId, 'UNRESOLVED');
        if (response?.status === 'UNRESOLVED') {
          await updateAlertsIncrementally(dispatch, selectedSiteId, null, 25, '', [], []);
          resolve('done');
        } else {
          console.log('event could not be read');
        }
      })
     
    });
    Promise.all(convertPromises).then((result) => {
      console.log(result);
      targetTable.querySelectorAll('.events-checkbox').forEach( el => el.checked = false );
      props.insideLog ? props.setEventLoading(false) : props.setAlertLoading(false);
      setSelectedEvents([]);
    });
  };

  const convertSelectedToUnread = () => {
    const convertPromises = selectedEvents.map((event) => { 
      return new Promise (async(resolve) => {
        props.insideLog ? props.setEventLoading(true) : props.setAlertLoading(true);
        await convertToUnread(event)
        resolve('done')
      }
    )
    });
    Promise.all(convertPromises).then((result) => {
      console.log(result)
      targetTable.querySelectorAll('.events-checkbox').forEach( el => el.checked = false );
      props.insideLog ? props.setEventLoading(false) : props.setAlertLoading(false);
      setSelectedEvents([]);
    })
  };

  const handleChangeThreatLevel = (event, alert) => {
    setContextBoxPoints({
      x: event.pageX,
      y: event.pageY,
    });
    setShowThreat(!showThreat)
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setSelectedEvent(alert);
  }

  useEffect(() => {
    if (newEvent) {
      const newArray = displayedEvents.map((event) => event.eventId === newEvent.eventId ? {...event, status: newEvent.status, threatLevel: newEvent.threatLevel} : event);
      setDisplayedEvents(() => newArray)
    }
  }, [newEvent]);

  const setThreatLevel = async (level) => {
    if (selectedEvent) {
      const updatedEvent = await updateThreatLevel(selectedSiteId, selectedEvent.eventId, level);
      setNewEvent(updatedEvent);
      setSelectedEvent(null);
    }  else if (selectedEvents.length > 0) {
        const threatLevelPromises = selectedEvents.map((event) => {
          return new Promise (async(resolve) => {
            props.insideLog ? props.setEventLoading(true) : props.setAlertLoading(true);
            const updatedEvent = await updateThreatLevel(selectedSiteId, event.eventId, level, alertsOrder);
            setNewEvent(updatedEvent);
            resolve('done');
          })
        });
        Promise.all(threatLevelPromises).then((result) => { (async()=> {
          console.log(result);
          targetTable.querySelectorAll('.events-checkbox').forEach( el => el.checked = false );
          props.insideLog ? props.setEventLoading(false) : props.setAlertLoading(false);
          setSelectedEvents([]);
          result =[];
        })();
        })
      }
      setShowThreat(false);
      setSelectedTagFilter('All');
  };

  const getColor = (alert) => {
    const level = alert.threatLevel;
    if (level === "TRESPASS") return "red";
    else if (level === "TAMPERING") return "orange";
    else if (level === "SUSPICIOUS" ) return "yellow";
    else if (level === "NORMAL") return "lightgray"
    else return "";
  };

  const closeOpenMenus = (e)=>{
    if(showThreat && !(tagsMenu.current?.contains(e.target))){
      setShowThreat(false)
    }
}

document.addEventListener('click',closeOpenMenus)
  const handleTag = (event) => {
    setContextBoxPoints({
      x: event.pageX,
      y: event.pageY,
    });
    setShowThreat(!showThreat)
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  useEffect(() => {
    if (selectedTagFilter === 'All') setDisplayedEvents(props.list);
    else {
      if (selectedTagFilter === 'Trespass') setDisplayedEvents(props.list.filter((event) => event.threatLevel === 'TRESPASS'));
      else if (selectedTagFilter === 'Tampering') setDisplayedEvents(props.list.filter((event) => event.threatLevel === 'TAMPERING'));
      else if (selectedTagFilter === 'Suspicious') setDisplayedEvents(props.list.filter((event) => event.threatLevel === 'SUSPICIOUS'));
    }
  }, [selectedTagFilter, props.list]);

  return (
    <>
          <div className="events-table-container" onClick={(e) => setTargetTable(e.currentTarget)}>
            {showComments &&  <CommentPopup selectedEvent={selectedEvent} closePopup={() => setShowComments(false)} selectedOptionLogIndex={props.selectedOptionLogIndex} sourceType={props.sourceType} startTime={startTimestamp} endTime={endTimestamp}/>}
          {showPopup && props.insideLog
        && (
        <Popup
          closePopup={() => setShowPopup(false)}
          title="Events Filter"
          child={(
            <EventsFilter
              updateSource={props.setSourceType}
              sourceType={props.sourceType}
              setStartTimestamp={setStartTimestamp}
              setEndTimestamp={setEndTimestamp}
              setShowPopup={setShowPopup}
              tag={selectedTagFilter}
              updateTag={setSelectedTagFilter}
              setLogFilterStartTime={props.setLogFilterStartTime}
              setLogFilterEndTime={props.setLogFilterEndTime}
              startDate={startDate}
              endDate={endDate}
              startTime={startTime}
              endTime={endTime}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setStartDateObject={setStartDateObject}
              setEndDateObject={setEndDateObject}
              setErrorMessage={setErrorMessage}
              errorMessage={errorMessage}
            />
              )}
        />
        )}
        {showPopup && !props.insideLog 
        && (
          <Popup 
            closePopup={() => setShowPopup(false)}
            title="Events Filter"
            child={(
              <AlertsFilter tag={selectedTagFilter} updateTag={setSelectedTagFilter} setShowPopup={setShowPopup} />
            )}
              />
        )}
            <div className="events-table-banner">
              <div>
                {!props.insideLog && <button disabled={buttonsState.archive} onClick={(e) => archiveEvents(e)}>
                  <img className="banner-icons" src={archive} alt="archive" />
                  Archive</button>}
                {props.insideLog && <button disabled={buttonsState.download} onClick={downloadSelectedEvents}>
                  <img className="banner-icons" src={download} alt="download" />
                  Download</button>}
                {!props.insideLog && <button disabled={buttonsState.read} onClick={convertSelectedToRead}>
                  <img className="banner-icons" id="mark-as-read" src={read} alt="read" />
                  Mark as Read</button>}
                {!props.insideLog && <button disabled={buttonsState.unread} onClick={convertSelectedToUnread}>
                  <img className="banner-icons" src={unread} alt="unread" />
                  Mark as Unread</button>}
                 <button id={"tag-button"} disabled={buttonsState.flag} onClick={(e) => handleTag(e)}>
                  <img className="banner-icons" src={tag} alt="tag" />
                  Tag as</button>
              </div>
              <div>
              <button className="right-buttons" disabled={props.list.length === 0} onClick={(e) => handleSelectClick(e)}>
                <img className="banner-icons select-icon" src={select} alt="select" />
                Select</button>
                {showCheckbox && <span className="selected-number">{selectedEvents.length}</span>}
                <button className="right-buttons" onClick={() => setShowPopup(!showPopup)} >
                  <img className="banner-icons" src={filter} alt="filter" />
                  Filter</button>
              </div>
            </div>
      {displayedEvents?.length
        ? (
            <div className="scrollable-wrapper" onScroll={handleScroll}>
              <table className="alerts-table wide-screen">
                <thead>
                  <tr>
                    <th/>
                    <th className="select-cell">
                      { showCheckbox && <input id="select-all-cb" type="checkbox" value="selected" className="events-checkbox" onClick={selectAll}/>}
                    </th>
                    <th>Source</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Message</th>
                    <th>Notes</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {rightClick && targetAlert?.status === 'UNRESOLVED' && <div className="context-menu" style={{ position: 'absolute', top: `${contextBoxPoints.y}px`, left: `calc(${contextBoxPoints.x}px - 32vw)` }} onClick={convertToUnread(targetAlert)}>Mark as Unread</div>}
                  {showThreat
                  && 
                    <div ref={tagsMenu}  id="threat-menu" className="threat-context-menu" style={{ position: 'absolute', top: `calc(${contextBoxPoints.y}px - 60px)`, left: `calc(${contextBoxPoints.x}px - 42vw)` }}>
                        <h4 className="black">Tag as</h4>
                        <ul>
                        <li onClick={() => setThreatLevel('TRESPASS')}>TRESPASS</li>
                          <li onClick={() => setThreatLevel('TAMPERING')}>TAMPERING</li>
                          <li onClick={() => setThreatLevel('SUSPICIOUS')}>SUSPICIOUS</li>
                          <li onClick={() => setThreatLevel('NORMAL')}>NORMAL</li>
                        </ul>
                    </div>}
                  {displayedEvents.map((alert) => (
                    <tr key={alert.eventId} onContextMenu={(e) => showMenu(e, alert)} onClick={() => readEvent(alert)} className={alert?.status === 'NEW' && !props.insideLog  ? 'white' : 'grey'}>
                       <td className={`threat-tag ${getColor(alert)}`} onClick={(e) => handleChangeThreatLevel(e, alert)}>
                        <img src={leftAffrow} className="left-arrow-icon" alt="left-arrow" />
                      </td>
                      <td> { showCheckbox && <input id={`cb-${alert.eventId}`} type="checkbox" value="selected" className="events-checkbox" onClick={(event) => handleCheckbox(event, alert)}/> }</td>
                      <td>{formatSource(alert.source)}</td>
                      <td>{formatDate(alert.eventId, timeZone)}</td>
                      <td>{formatTime(alert.eventId, timeZone)}</td>
                      <td>
                        <p className="mb-05">{alert.content?.message}</p>
                        <img src={alert.content?.imageUrl} alt="" className="video-img" />
                      </td>

                      {alert.comments?.length > 0
                        ? <td>{alert.comments[0].comment}</td>
                        : <td />}

                      <td>
                        <button onClick={(event) => openComments(event, alert)} className="comment">
                          <div className={`bubble-wrapper ${alert?.comments?.length > 0 ? 'visible' : ''}`}>
                            <FontAwesomeIcon icon={faCircle} className="comment-bubble" />
                            <span>{alert?.comments?.length}</span>
                          </div>
                          <img src={comment} alt="comment" />
                        </button>
                        {!props.insideLog && <button><img src={archive} onClick={(e) => archiveDirectly(dispatch, e, alert?.eventId, selectedSiteId, null, alertsOrder)} alt="archive" /></button>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table className="alerts-table small-screen">
                <thead>
                  <tr>
                  <th/>
                    <th className="select-cell">
                    { showCheckbox && <input id="select-all-cb" type="checkbox" value="selected" className="events-checkbox" onClick={(e) => selectAll(e)}/>}
                    </th>
                    <th>Source</th>
                    <th>Message</th>
                    <th>Notes</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {rightClick && targetAlert?.status === 'UNRESOLVED' && <div className="context-menu" style={{ position: 'absolute', top: `${contextBoxPoints.y}px`, left: `calc(${contextBoxPoints.x}px - 32vw)` }} onClick={convertToUnread(targetAlert)}>Mark as Unread</div>}
                  {showThreat
                  && 
                    <div className="threat-context-menu" style={{ position: 'absolute', top: `calc(${contextBoxPoints.y}px - 60px)`, left: `calc(${contextBoxPoints.x}px - 42vw)` }}>
                        <h4 className="black">Select a threat level</h4>
                        <ul>
                          <li onClick={() => setThreatLevel('TRESPASS')}>TRESPASS</li>
                          <li onClick={() => setThreatLevel('TAMPERING')}>TAMPERING</li>
                          <li onClick={() => setThreatLevel('SUSPICIOUS')}>SUSPICIOUS</li>
                          <li onClick={() => setThreatLevel('NORMAL')}>NORMAL</li>
                        </ul>
                    </div>}
                  {displayedEvents.map((alert) => (
                    <tr key={alert.eventId} onContextMenu={(e) => showMenu(e, alert)} onClick={() => readEvent(alert)} className={alert?.status === 'NEW' ? 'white' : 'grey'}>
                      <td className={`threat-tag ${getColor(alert)}`}  onClick={(e) => handleChangeThreatLevel(e, alert)}>
                        <img src={leftAffrow} className="left-arrow-icon" alt="left-arrow" />
                      </td>
                      <td>{ showCheckbox &&<input type="checkbox" id={`cb-${alert.eventId}`} value="selected" className="events-checkbox" onClick={(event) => handleCheckbox(event, alert)}/> }</td>
                      <td><b>Source: </b>{formatSource(alert.source)} <br/> <b>Date: </b>{formatDate(alert.eventId, timeZone)} <br/> <b>Time: </b>{formatTime(alert.eventId, timeZone)}</td>
                      <td>
                        <p className="mb-05">{alert.content?.message}</p>
                        <img src={alert.content?.imageUrl} alt="" className="video-img" />
                      </td>

                      {alert.comments?.length > 0
                        ? <td>{alert.comments[0].comment}</td>
                        : <td />}

                      <td className='alert-table-icons'>
                        <button onClick={(event) => openComments(event, alert)} className="comment">
                          <div className={`bubble-wrapper ${alert?.comments?.length > 0 ? 'visible' : ''}`}>
                            <FontAwesomeIcon icon={faCircle} className="comment-bubble" />
                            <span>{alert?.comments?.length}</span>
                          </div>
                          <img src={comment} alt="comment" />
                        </button>
                        {!props.insideLog && <button><img src={archive} onClick={(e) => archiveDirectly(dispatch, e, alert?.eventId, selectedSiteId, null, alertsOrder)} alt="archive" /></button>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
        )
        : (
          <div className="white-wrapper">
            <p>{`No ${props.insideLog ? 'events' : 'alerts'}`}</p>
          </div>
        )}
        </div>
    </>
  );
}

export default EventsTable;
