/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import rightChevron from '../assets/right-chevron.svg';

function Dropdown(props) {
  const [mainSelectedOptionIndex, setMainSelectedOptionIndex] = useState(null);
  const [dropdown, setDropdown] = useState(false);
  const [imgClassName, setImgClassName] = useState(true);

  useEffect(() => {
    if (props.hideFirstOption !== true) {
      if (props.firstSelectedOptionIndex) {
        setMainSelectedOptionIndex(props.firstSelectedOptionIndex);
      } else {
        setMainSelectedOptionIndex(0);
      }
    } else {
      setMainSelectedOptionIndex(null);
    }
  }, [props.firstSelectedOptionIndex, props.hideFirstOption]);

  // When user clicks  - toggle dropdown
  const handleClick = () => {
    setImgClassName(!imgClassName);
    setDropdown(!dropdown);
  };

  const handleClickOption = (index) => {
    setMainSelectedOptionIndex(index);
    handleClick();
    props.updateOption(index);
  };
  return (
    <div style={{ zIndex: 4 }}>
      <button id="dropId" className={props.className ?? 'dropdown-btn'} onClick={handleClick}>
        {props.firstRowTag
          ? (
            <props.firstRowTag>
              <span>{props.label}</span>
              {props.options[mainSelectedOptionIndex]}
            </props.firstRowTag>
          )
          : (
            <h4>
              <span>{props.label}</span>
              {' '}
              {props.options[mainSelectedOptionIndex]}
            </h4>
          )}
        <img src={rightChevron} alt="down" id="down-arrow-4" className={`chevron ${props.blackArrow ? '' : 'white'} ${imgClassName ? 'down' : 'flip'}`} />
      </button>

      {dropdown
        ? (
          <div>
            <ul>
              {props.options.map((option, index) => (
                <li key={index} className={`drop-row ${props.className && props.className.includes('archive') ? 'archived-row' : ''}`}>
                  <h4 className="black" key={index} onClick={() => handleClickOption(index)}>{option}</h4>
                  {props.options2 && props.options2.length > 0
                    && <h5 className={props.className2}>{props.options2[index]}</h5>}
                </li>
              ))}
            </ul>
            { props.fixedButtonLabel
          && (
          <div className="drop-row fixed-btn">
            <h3 className="black" onClick={() => props.fixedButtonFuntion()}>{props.fixedButtonLabel}</h3>
          </div>
          )}
          </div>
        )
        : null}
    </div>
  );
}
export default Dropdown;
