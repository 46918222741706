import React, { useEffect, useState } from 'react';
import Dropdown from './Dropdown';

function EventsTagsFilter(props) {
  const eventsTypesOptions = ['All', 'Trespass', 'Tampering', 'Suspicious'];
  const [dropDownIndex, setDropDownIndex] = useState(0);

  useEffect(() => {
    const index = eventsTypesOptions.indexOf(props.tag);
    if (index > 0) setDropDownIndex(index);
    else setDropDownIndex(0);
  }, [props.tag]);

  const selectType = (index) => {
    props.updateTag(eventsTypesOptions[index]);
  };

  return (
    <div className="events-type-filter">
      <Dropdown options={eventsTypesOptions} label="Tag : " updateOption={selectType} firstSelectedOptionIndex={dropDownIndex} />
    </div>
  );
}

export default EventsTagsFilter;
