/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import React, { useSelector, useDispatch } from 'react-redux';
import { archiveSelectedProperty } from '../api/UserAPI';
import { updateRooms, updateDevices } from './Update';
import { updateCurrentTab } from '../redux/userInfo';

function ArchivePopup(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deviceList = useSelector((state) => state.userInfo.deviceList);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const [error] = useState(false);

  const archiveProperty = async () => {
    try {
      const response = await archiveSelectedProperty(selectedSiteId, props.selectedRoomId);
      console.log(response);
      await updateRooms(dispatch, selectedSiteId);
      await updateDevices(dispatch, selectedSiteId);
      dispatch(updateCurrentTab('Alerts'));
      navigate('/home');
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div id="archive-popup" className="popup">
      <div className="popup-content">
        <h2 className="black">Archive Property</h2>
        <div className="confirm-message">
          <p className="display-linebreak">Are you sure you want to archive this property?</p>
          <p>
            Any active alerts will be closed and you will no longer have
            access to the devices through this property file.
          </p>
          <br />
          <p>Activity log will remain accessible.</p>
          { error && <p className="error">Error archiving the property!</p>}
        </div>
        <button className="primary-btn mb-1" onClick={() => archiveProperty(deviceList, selectedSiteId)}>Yes, continue.</button>
        <button className="primary-btn" onClick={() => props.setState({ archivePopup: false })}>Cancel</button>
      </div>
    </div>
  );
}

export default ArchivePopup;
