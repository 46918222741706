/* eslint-disable */
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAutomations, patchAutomation } from '../api/UserAPI';
import clipboard from '../assets/clipboard.svg';
import { updateAutomationList } from '../redux/userInfo';
import './Automations.css';
import ActiveAlertsContainer from './ActiveAlertsContainer';

function AutomationsTab(props) {
  // Site info
  const dispatch = useDispatch();
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const selectedRoomId = useSelector((state) => state.userInfo.selectedRoomId);
  const automationList = useSelector((state) => state.userInfo.automationList);
  const deviceList = useSelector((state) => state.userInfo.deviceList);
  const activeList = useSelector((state) => state.userInfo.activeList);
  const [filteredDeviceList, setFilteredDeviceList] = useState([]);

  useEffect(() => {
    // get filtered list of devices in selected room
    const filteredDeviceListTmp = deviceList?.devices?.filter(
      (device) => device?.roomId === selectedRoomId,
    );
    setFilteredDeviceList(filteredDeviceListTmp);
  }, [activeList, deviceList, selectedRoomId]);

  // ----------------------------------------------------------------------
  // get list of hostId's in selected room
  // const hostIdList = filteredDeviceList?.map(({ hostId }) => (hostId));

  // get filtered list of automations in selected room
  // const filteredAutomationList = automationList?.automations?.filter(
  //   (automation) => { return hostIdList?.includes(automation?.parent?.id) }
  // )
  // ----------------------------------------------------------------------

  // Toggle automation on/off
  const toggleAutomation = async (automationId, toggleValue) => {
    const response1 = await patchAutomation(selectedSiteId, automationId, toggleValue);
    console.log(response1);
    // update automations
    const response2 = await getAutomations(selectedSiteId);
    if (response2?.automations?.length) {
      dispatch(updateAutomationList(response2));
    }
  };

  // Return icon for rule/schedule
  const displayIcon = (type) => {
    if (type === 'rules') {
      return (
        <div className="icon-wrapper">
          <FontAwesomeIcon icon={faCircle} className="circle-rule" />
          <img src={clipboard} alt="clipboard" className="icon-filter-white" />
        </div>
      );
    }
    if (type === 'schedule') {
      return <FontAwesomeIcon icon={faCircle} className="circle-schedule" />;
    }
  };

  // Return device name
  const deviceName = (type, id) => {
    let name = '';
    let insertDeviceName = '';
    let hostDeviceName = '';

    if (type === 'insert') {
      const insertDevice = filteredDeviceList?.filter((device) => device?.deviceId === id);
      if (insertDevice) {
        insertDeviceName = insertDevice[0]?.host?.components[0]?.name;
      }
      name = insertDeviceName || type;
    } else if (type === 'host') {
      const hostDevice = filteredDeviceList?.filter((device) => device?.hostId === id);
      if (hostDevice) {
        hostDeviceName = hostDevice[0]?.host?.components[0]?.name;
      }
      name = hostDeviceName || type;
    }

    return name;
  };

  const displayOccupancyMode = (mode) => {
    let result = '';
    if (mode?.occupancy_vacancy?.args?.turnOnWhenOccupied === true) {
      result = 'Mode: Occupancy';
    } else {
      result = 'Mode: Vacancy';
    }
    return result;
  };

  const displayOccupancyAction = (mode) => {
    let result = '';
    if (mode?.occupancy_vacancy?.args?.turnOffAfter) {
      let duration = mode?.occupancy_vacancy?.args?.turnOffAfter;
      duration = getConvertedTime(duration);
      result = `- Turn OFF after unoccupied for ${duration}`;
    }
    return result;
  };

  // Return rule
  const displayRule = (type, trigger) => {
    let result = '';

    if (type === 'rules') {
      const type = trigger?.type;
      const conditionOne = trigger?.args[0];
      let conditionTwo = trigger?.args[1];
      const conditionThree = trigger?.args[2];

      // 1 - Power Toggled (host triggered)
      if (type === 1) {
        if (conditionOne === 1) {
          result = 'When turned on:';
        } else if (conditionOne === 0) {
          result = 'When turned off:';
        }
      }

      // 3 - Energy (host triggered)
      else if (type === 3) {
        if (conditionOne === 1) {
          result = `When power exceeds ${conditionTwo}W for ${conditionThree} mins:`;
        } else if (conditionOne === 0) {
          result = `When power drops below ${conditionTwo}W for ${conditionThree} mins:`;
        }
      }

      // 4 - Motion
      else if (type === 4) {
        if (conditionOne === 1 && conditionTwo === 0) {
          result = 'When occupancy begins:';
        } else if (conditionOne === 1 && conditionTwo > 0) {
          result = `When occupied for ${conditionTwo} mins:`;
        } else if (conditionOne === 0 && conditionTwo === 0) {
          result = 'When occupancy ends:';
        } else if (conditionOne === 0 && conditionTwo > 0) {
          result = `When unoccupied for ${conditionTwo} mins:`;
        }
      }

      // 5 - Temp (insert triggered)
      else if (type === 5) {
        if (conditionOne === 1) {
          result = `When temperature exceeds ${conditionTwo}°C for ${conditionThree} mins:`;
        } else if (conditionOne === 0) {
          result = `When temperature drops below ${conditionTwo}°C for ${conditionThree} mins:`;
        }
      }

      // 6 - Humi (insert triggered)
      else if (type === 6) {
        if (conditionOne === 1) {
          result = `When humidity threshold exceeds ${conditionTwo}°C for ${conditionThree} mins:`;
        } else if (conditionOne === 0) {
          result = `When humidity threshold drops below ${conditionTwo}°C for ${conditionThree} mins:`;
        }
      }

      // 7 - Air Quality
      else if (type === 7) {
        // to do: double check this value
        conditionTwo = getAqiLabel(conditionTwo);

        if (conditionOne === 1) {
          result = `When air quality declines to ${conditionTwo} for ${conditionThree} mins:`;
        } else if (conditionOne === 0) {
          result = `When air quality improves to ${conditionTwo} for ${conditionThree} mins:`;
        }
      }

      // 24 - Power out
      else if (type === 24) {
        if (conditionOne === 1) {
          result = `When connected for ${conditionTwo} mins:`;
        } else if (conditionOne === 0) {
          result = `When disconnected for ${conditionTwo} mins:`;
        }
      }

      // 25 - Device Restarted (host triggered)
      else if (type === 25) {
        result = 'When power restored:';
      } else {
        result = 'Trigger not found';
      }
    }
    return result;
  };

  // Return schedule start message
  const displayScheduleStart = (type, payload) => {
    let result = '';

    if (type === 'schedule' && payload?.action) {
      const action = getAction(payload?.action?.type);
      const hour = payload?.startTime?.hour;
      const minute = payload?.startTime?.minute;
      let offSetSpecial = null;
      if (payload?.startTime?.args && Number.isInteger(payload?.startTime?.args[0])) {
        offSetSpecial = payload?.startTime?.args[0];
      }
      const offset = getOffset(hour, minute, offSetSpecial);
      const special = getSpecial(payload?.startTime?.special);
      result = action + offset + special;
    }

    return result;
  };

  // Return schedule end message
  const displayScheduleEnd = (type, payload) => {
    let result = '';

    if (type === 'schedule' && payload?.endAction) {
      const action = getAction(payload?.endAction?.type);
      const hour = payload?.endTime?.hour;
      const minute = payload?.endTime?.minute;
      let offSetSpecial = null;
      if (payload?.endTime?.args && Number.isInteger(payload?.endTime?.args[0])) {
        offSetSpecial = payload?.endTime?.args[0];
      }
      const offset = getOffset(hour, minute, offSetSpecial);
      const special = getSpecial(payload?.endTime?.special);
      result = action + offset + special;
    }

    return result;
  };

  // Get action
  const getAction = (input) => {
    let result = '';
    if (input === 1) {
      result = 'Turn on ';
    } else if (input === 2) {
      result = 'Turn off ';
    } else if (input === 3) {
      result = 'Dim ';
    } else if (input === 4) {
      result = 'Send Notification ';
    }
    return result;
  };

  // Get offset
  const getOffset = (hour, minute, offSetSpecial) => {
    let result = '';

    if (hour || minute) {
      const units = hour < 12 ? ' AM' : ' PM';
      hour = +hour % 12 || 12;
      if (minute < 9) {
        minute = `0${minute}`;
      }
      result = `at ${hour}:${minute}${units}`;
    }

    if (offSetSpecial < 0) {
      result = `${Math.abs(offSetSpecial)} mins before `;
    } else if (offSetSpecial === 0) {
      result = 'at ';
    } else if (offSetSpecial > 0) {
      result = `${Math.abs(offSetSpecial)} mins after `;
    }

    return result;
  };

  // Get special (sunrise/sunset)
  const getSpecial = (input) => {
    let result = '';
    if (input === 101) {
      result = 'sunrise';
    } else if (input === 102) {
      result = 'sunset';
    }
    return result;
  };

  // OPTIONAL - returns days of the week that schedule is active
  // [Array of INT: 0..6]
  const displayWeekdays = (weekdays) => {
    let result = '';

    if (weekdays) {
      let sun = <span>S</span>;
      let mon = <span>M</span>;
      let tues = <span>T</span>;
      let wed = <span>W</span>;
      let thurs = <span>T</span>;
      let fri = <span>F</span>;
      let sat = <span>S</span>;
      if (weekdays.includes(0)) { sun = <span className="chosen">S</span>; }
      if (weekdays.includes(1)) { mon = <span className="chosen">M</span>; }
      if (weekdays.includes(2)) { tues = <span className="chosen">T</span>; }
      if (weekdays.includes(3)) { wed = <span className="chosen">W</span>; }
      if (weekdays.includes(4)) { thurs = <span className="chosen">T</span>; }
      if (weekdays.includes(5)) { fri = <span className="chosen">F</span>; }
      if (weekdays.includes(6)) { sat = <span className="chosen">S</span>; }

      result = (
        <>
          {sun}
          {' '}
          {mon}
          {' '}
          {tues}
          {' '}
          {wed}
          {' '}
          {thurs}
          {' '}
          {fri}
          {' '}
          {sat}
        </>
      );
    }
    return result;
  };

  // Actions (only for rules)
  const displayAction = (num) => {
    let action = '';
    switch (num) {
      case 1:
        action = '- Turn On \n';
        break;
      case 2:
        action = '- Turn Off \n';
        break;
      case 4:
        action = '- Send Notification \n';
        break;
      case 5:
        action = '- USB On \n';
        break;
      case 6:
        action = '- USB Off \n';
        break;
      case 7:
        action = '- Cycle Power \n';
        break;
      default:
        action = '- Action not found \n';
    }
    return action;
  };

  // Return air quality index label
  const getAqiLabel = (level) => {
    let aqiLabel = '';
    switch (level) {
      case 1:
        aqiLabel = 'Excellent';
        break;
      case 2:
        aqiLabel = 'Good';
        break;
      case 3:
        aqiLabel = 'Moderate';
        break;
      case 4:
        aqiLabel = 'Poor';
        break;
      case 5:
        aqiLabel = 'Bad';
        break;
      case 6:
        aqiLabel = 'Severe';
        break;
      case 7:
        aqiLabel = 'Extreme';
        break;
      default:
        aqiLabel = '';
    }
    return aqiLabel;
  };

  // Return time as hours & minutes
  const getConvertedTime = (totalMinutes) => {
    let result = '';
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (hours > 0 && minutes > 0) {
      result = `${hours}h ${minutes}min`;
    } else if (hours > 0 && minutes === 0) {
      result = `${hours}h `;
    } else {
      result = `${minutes}min`;
    }

    return result;
  };

  // Check if user has automations
  const checkAutomations = () => {
    const tempList = [];
    let result = true;
    if (filteredDeviceList?.length) {
      for (const device of filteredDeviceList) {
        if (device?.automationModes[0]?.occupancy_vacancy?.isActive) {
          tempList.push(device.automationModes);
        }
      }
    }
    if (tempList.length === 0 && automationList?.automations === '') {
      result = false;
    }
    return result;
  };

  // Display automations in selected site
  function DisplayAutomations() {
    const hasAutomations = checkAutomations();

    return (
      <>
        {hasAutomations
          ? (
            <table className="mb-25">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Device</th>
                  <th>Automation</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {automationList?.automations?.length ? (
                  automationList?.automations.map((automation) => (
                    <tr key={automation.id}>
                      <td>
                        <div className="first-cell">
              {displayIcon(automation?.type)}
              {automation?.name}
            </div>
                      </td>

                      <td className="mb-05">{deviceName(automation.parent?.type, automation.parent?.id)}</td>

                      <td>
                        <div>{displayRule(automation?.type, automation.payload?.trigger)}</div>
                        <div>{displayScheduleStart(automation?.type, automation.payload)}</div>
                        <div>{displayScheduleEnd(automation?.type, automation.payload)}</div>
                        <div>{automation.payload?.targets?.map((target) => (displayAction(target.action.type)))}</div>
                        <div>{displayWeekdays(automation.payload?.weekdays)}</div>
                      </td>

                      <td>
                        <div className="last-cell">
              {/* <img src={edit} alt='edit' className='edit-btn icon-filter-white'></img> */}
              <label className="switch">
                          <input
                            type="checkbox"
                            checked={automation?.payload?.enabled}
                            onChange={(e) => toggleAutomation(automation.id, e.target.checked)}
                          />
                          <span className="slider" />
                        </label>
            </div>
                      </td>
                    </tr>
                  ))
                ) : null}

                {filteredDeviceList?.length ? (
                  filteredDeviceList.map((device) => (
                    <React.Fragment key={device.deviceId}>
                      {device?.automationModes?.length ? (
                        device?.automationModes?.map((mode, index) => (
              <tr key={index}>
                          {mode?.occupancy_vacancy?.isActive ? (
                            <>
                              <td className="first-cell">
                                <div><FontAwesomeIcon icon={faCircle} className="circle-mode" /></div>
                                <div>Occupancy/Vacancy Mode</div>
                              </td>

                              <td><div className="mb-05">{device?.host?.components[0]?.name}</div></td>

                              <td>
                                <div>{displayOccupancyMode(mode)}</div>
                                <div>{displayOccupancyAction(mode)}</div>
                              </td>

                              <td className="last-cell">
                                {/* <img src={edit} alt='edit' className='edit-btn icon-filter-white'></img> */}
                                {/* <label className='switch'>
                                <input
                                  type='checkbox'
                                ></input>
                                <span className='slider'></span>
                              </label> */}
                              </td>
                            </>
                          )

                            : mode?.iaq?.isActive ? (
                              <>
                                <td className="first-cell">
                                  <div><FontAwesomeIcon icon={faCircle} className="circle-mode" /></div>
                                  <div>Air Exchanger Mode</div>
                                </td>

                                <td><div className="mb-05">{device?.host?.components[0]?.name}</div></td>

                                <td>
                                  <div>
                                    Air Quality:
                                    {getAqiLabel(mode?.iaq?.args?.aqLevel)}
                                  </div>
                                </td>

                                <td className="last-cell">
                                  {/* <img src={edit} alt='edit' className='edit-btn icon-filter-white'></img> */}
                                  {/* <label className='switch'>
                                <input
                                  type='checkbox'
                                ></input>
                                <span className='slider'></span>
                              </label> */}
                                </td>
                              </>
                            )

                              : mode?.ventilation?.isActive ? (
                                <>
                                  <td className="first-cell">
                                    <div><FontAwesomeIcon icon={faCircle} className="circle-mode" /></div>
                                    <div>Bathroom Fan Mode</div>
                                  </td>

                                  <td><div className="mb-05">{device?.host?.components[0]?.name}</div></td>

                                  <td>
                                    <div>
                                      Humidity:
                                      {mode?.ventilation?.args?.humidity}
                                      %
                                    </div>
                                  </td>

                                  <td className="last-cell">
                                    {/* <img src={edit} alt='edit' className='edit-btn icon-filter-white'></img> */}
                                    {/* <label className='switch'>
                                <input
                                  type='checkbox'
                                ></input>
                                <span className='slider'></span>
                              </label> */}
                                  </td>
                                </>
                              )

                                : mode?.disconnection?.isActive ? (
                                  <>
                                    <td className="first-cell">
                                      <div><FontAwesomeIcon icon={faCircle} className="circle-mode" /></div>
                                      <div>Connection Monitoring Mode</div>
                                    </td>

                                    <td><div className="mb-05">{device?.host?.components[0]?.name}</div></td>

                                    <td>
                                      <div>
                                        Disconnected for:
                                        {getConvertedTime(mode?.disconnection?.args?.disconnectedFor)}
                                      </div>
                                    </td>

                                    <td className="last-cell">
                                      {/* <img src={edit} alt='edit' className='edit-btn icon-filter-white'></img> */}
                                      {/* <label className='switch'>
                                <input
                                  type='checkbox'
                                ></input>
                                <span className='slider'></span>
                              </label> */}
                                    </td>
                                  </>
                                ) : null}
                        </tr>
                        ))
                      ) : null}
                    </React.Fragment>
                  ))
                ) : null}
              </tbody>
            </table>
          )
          : (
            <div className="white-wrapper">
              No automations found
            </div>
          )}
      </>
    );
  }

  return (
    <div>

      <div className="content">
        <h2 className="mb-05">Automations</h2>
        <DisplayAutomations />
        <ActiveAlertsContainer setAlertLoading={props.setAlertLoading} setSelectedEvent={props.setSelectedEvent} />
      </div>

    </div>
  );
}

export default AutomationsTab;
