import React from 'react';
import chat from '../assets/chat.svg';
import email from '../assets/email.svg';
import website from '../assets/website.svg';
import './Automations.css';
import ActiveAlertsContainer from './ActiveAlertsContainer';

function SupportTab(props) {
  return (
    <div>
      <div className="content">
        <h2 className="mb-075">Support</h2>
        <div className="white-wrapper support">
          <button className="primary-btn">
            <img src={chat} alt="chat" onClick={() => window.zE('messenger', 'open')} />
            Chat
          </button>
          <a className="primary-btn" href="mailto:support@swidget.com?subject=Support%20for%20Swidget%20Dashboard">
            <img src={email} alt="email" />
            Email
          </a>
          <a className="primary-btn" target="_blank" rel="noopener noreferrer" href="https://protech-support.swidget.com/">
            <img src={website} alt="website" />
            Website
          </a>
        </div>
        {props.showAlerts
        && (
        <ActiveAlertsContainer
          setAlertLoading={props.setAlertLoading}
          setSelectedEvent={props.setSelectedEvent}
        />
        )}
      </div>
    </div>
  );
}

export default SupportTab;
