import { CircularProgress, Stack } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEventsFile } from '../api/UserAPI';
import close from '../assets/close.svg';
import { updateAlertsOrder, updateLastDownloadAt } from '../redux/userInfo';
import './Automations.css';
import Dropdown from './Dropdown';
import ActiveAlertsContainer from './ActiveAlertsContainer';
import EventsTable from './EventsTable';
import { downloadCSV } from '../utils/utils';

function ActivityTab(props) {
  // site info
  const dispatch = useDispatch();
  const eventList = useSelector((state) => state.userInfo.eventList);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const selectedRoomId = useSelector((state) => state.userInfo.selectedRoomId);
  const lastDownloadAt = useSelector((state) => state.userInfo.lastDownloadAt);
  const archivedList = useSelector((state) => state.userInfo.archivedList);

  const dropdownOptions = ['Most Recent', 'Oldest - Newest'];
  const [activityLog, setActivityLog] = useState([]);

  useEffect(() => {
    // list of events where status = resolved (IN SELECTED ROOM)
    let activityLogTmp = [];
    if (eventList.length) {
      activityLogTmp = eventList?.filter(
        (event) => event?.content?.roomId === selectedRoomId && (event?.type === 'event' || event?.type === 'alert'),
      );
    }
    setActivityLog(activityLogTmp);
  }, [eventList, selectedRoomId]);

  const [state, setState] = useState({
    downloadPopup: false,
  });

  // to do: error handling
  // download all activity
  const downloadAll = async () => {
    const response = await getEventsFile('', selectedSiteId, selectedRoomId);
    downloadCSV(response);
    dispatch(updateLastDownloadAt(Date.now()));
  };

  // download new activity since last download
  const downloadNew = async () => {
    const response = await getEventsFile(lastDownloadAt, selectedSiteId, selectedRoomId);
    downloadCSV(response);
    dispatch(updateLastDownloadAt(Date.now()));
  };

  const updateAlertSortOption = (index) => {
    // eslint-disable-next-line no-unused-expressions
    index === 0 ? dispatch(updateAlertsOrder('desc')) : dispatch(updateAlertsOrder('asc'));
  };

  const updateLogSortOption = (index) => {
    props.setSelectedOptionLogIndex(index);
  };

  useEffect(() => {
    props.setSelectedOptionLogIndex(0);
  }, []);

  return (
    <div>

      {state.downloadPopup
        ? (
          <div id="download-popup" className="popup">
            <div className="popup-content">
              <img src={close} alt="close" className="close-btn" onClick={() => setState({ downloadPopup: false })} />
              <h2 className="black mb-2">Download Activity Log</h2>
              <button className="primary-btn long mb-1" onClick={downloadAll}>Download all</button>
              <button className="primary-btn long" onClick={downloadNew}>Download new activity since last download</button>
            </div>
          </div>
        )
        : null}

      <div className="page-container content">

        <div className="section-header activity-log mb-05" style={{ height: '37px' }}>
          <div className="right activity-log">
            <Dropdown label="Sort by: " options={dropdownOptions} updateOption={updateLogSortOption} />
          </div>
        </div>
        <div>
          {props.isEventLoading
            ? (
              <Stack height={40} justifyContent="center" sx={{ color: 'white' }} spacing={2} direction="row">
                <CircularProgress color="inherit" />
                <h2>Activity Log</h2>
              </Stack>
            )
            : <h2>Activity Log</h2>}
        </div>
        <EventsTable
          selectedOptionLogIndex={props.selectedOptionLogIndex}
          list={activityLog}
          setSelectedEvent={props.setSelectedEvent}
          setEventLoading={props.setEventLoading}
          insideLog
          sourceType={props.sourceType === 'All' ? '' : props.sourceType}
          setSourceType={props.setSourceType}
          setLogFilterStartTime={props.setLogFilterStartTime}
          setLogFilterEndTime={props.setLogFilterEndTime}
        />

        {!archivedList?.includes(selectedRoomId)
          ? (
            <>
              {/* list of events where status = new / unresolved */}
              <div className="section-header mt-25" style={{ height: '37px', marginBottom: '0.6rem' }}>
                <Dropdown label="Sort by: " options={dropdownOptions} updateOption={updateAlertSortOption} />
              </div>
              <div>
                {props.isAlertLoading
                  ? (
                    <Stack height={35} justifyContent="center" sx={{ color: 'white' }} spacing={2} direction="row">
                      <CircularProgress color="inherit" />
                      <h2>Active Alerts</h2>
                    </Stack>
                  )
                  : <h2>Active Alerts</h2>}
              </div>
              <ActiveAlertsContainer
                hideTitle
                setAlertLoading={props.setAlertLoading}
                setSelectedEvent={props.setSelectedEvent}
              />
            </>
          )
          : null}

      </div>
    </div>
  );
}

export default ActivityTab;
