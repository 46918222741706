import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Hls from 'hls.js';
import moment from 'moment';
import { downloadMP4, getHLSStreamSessionUrl } from '../api/UserAPI';
import archive from '../assets/archive.svg';
import close from '../assets/close.svg';
import download from '../assets/download.svg';
import { clearCommentList, updateViewEventPopup } from '../redux/userInfo';
import { formatDate, formatSource, formatTime } from './FormatStuff';
import { archiveAlert } from './Update';

function ViewEventPopup(props) {
  const alertsOrder = useSelector((state) => state.userInfo.alertsOrder);
  const { selectedEvent } = props;
  const eventId = selectedEvent?.eventId;
  const [fragment, setFragment] = React.useState(null);
  const [streamSrc, setStreamSrc] = React.useState('');
  const [streamName, setStreamName] = React.useState(null);
  const [player, setPlayer] = React.useState(null);
  // site info
  const dispatch = useDispatch();
  const commentList = useSelector((state) => state.userInfo.commentList);
  const sites = useSelector((state) => state.userInfo.sites);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const selectedSite = sites ? sites.find((site) => site.siteId === selectedSiteId) : {};
  const timeZone = selectedSite?.config?.tz?.id;

  const fetchFragment = useCallback(async () => {
    const { selectedEvent } = props;
    if (selectedEvent?.source === 'video') {
      const minuteInMillis = 60000;
      let startTime = new Date(selectedEvent.eventId - minuteInMillis);
      let endTime = new Date(selectedEvent.eventId + minuteInMillis);
      if (selectedEvent.content?.start) {
        startTime = new Date(selectedEvent.content.start * 1000);
        endTime = new Date((selectedEvent.content.start + selectedEvent.content.duration) * 1000);
      }
      const { streamName } = selectedEvent.content;
      const hlsSession = await getHLSStreamSessionUrl(startTime, endTime, streamName);
      setStreamName(streamName);
      setStreamSrc(hlsSession.HLSStreamingSessionURL);
      const player = new Hls();
      const video = document.getElementById(`video-${selectedEvent.eventId}`);
      player.loadSource(hlsSession.HLSStreamingSessionURL);
      player.attachMedia(video);
      player.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });
      setFragment({
        StreamName: streamName,
        ProducerTimestamp: startTime,
        ProducerEndTimestamp: endTime,
      });
    }
  }, [props]);

  useEffect(() => {
    if (player) {
      player.src({
        src: streamSrc,
        type: 'application/x-mpegURL',
      });
      player.play();
    }
  }, [player]);

  useEffect(() => {
    fetchFragment();
  }, [fetchFragment]);

  const closePopup = () => {
    if (player) {
      setPlayer(null);
    }
    dispatch(updateViewEventPopup(false));
    dispatch(clearCommentList());
  };

  const archiveAndClose = () => {
    archiveAlert(dispatch, eventId, props.selectedSiteId, props.roomId, alertsOrder);
    closePopup();
  };
  const fetchClip = async () => {
    try {
      if (!fragment) return;
      const fragmentStartTime = fragment.ProducerTimestamp;
      const fragmentEndTime = fragment.ProducerEndTimestamp;
      const clip = await downloadMP4(fragmentStartTime, fragmentEndTime, streamName);
      if (clip.Payload.buffer) {
        const blob = new Blob([clip.Payload.buffer], { type: 'video/mp4' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', `${eventId}.mp4`);
        a.click();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getEventSourceText = (source) => {
    if (source === 'video') {
      return (<b><p className="mb-1">VIDEO EVENT</p></b>);
    } if (source === 'snapshot') {
      return (<b><p className="mb-1">SNAPSHOT EVENT</p></b>);
    }
    return null;
  };

  const getMediaElement = (selectedEvent, streamSrc) => {
    if (selectedEvent.source === 'video') {
      if (streamSrc) {
        return (
          <video id={`video-${selectedEvent.eventId}`} className="event-video player video-js vjs-default-skin" controls autoPlay loop />
        );
      }
      return (
        <video poster={selectedEvent.content?.imageUrl} alt="" className="video-img" controls={false} />
      );
    } if (selectedEvent.source === 'snapshot') {
      return (
        <img src={selectedEvent.content?.imageUrl} alt="" className="video-img" />
      );
    }
    return null;
  };

  return (
    <div id="view-event-popup" className="popup">
      <div className="popup-content">

        <h2 className="black mb-2">{selectedEvent.content?.roomName}</h2>
        <img src={close} alt="close" className="close-btn" onClick={() => closePopup()} />

        <div className="wrapper mb-15">
          <div className="left-side">
            {selectedEvent.content?.message
              ? <h3 className="comment-header black mb-1">{selectedEvent.content?.message}</h3>
              : null}

            {getMediaElement(selectedEvent, streamSrc)}
          </div>

          <div className="right-side">
            {getEventSourceText(selectedEvent?.source)}

            <p>
              <b>Source:</b>
              {' '}
              {formatSource(selectedEvent.source)}
            </p>
            <p>
              <b>Date:</b>
              {' '}
              {formatDate(selectedEvent.eventId, timeZone)}
            </p>
            <p className="mb-1">
              <b>Time:</b>
              {' '}
              {formatTime(selectedEvent.eventId, timeZone)}
            </p>

            <p>
              <b>Device:</b>
              {' '}
              {selectedEvent.content?.deviceName}
            </p>
            <p className="mb-15">
              <b>Market:</b>
              {' '}
              {selectedEvent.content?.siteName}
            </p>

            <div className="btn-wrapper">
              { !(props.selectedEvent.type === 'event' || props.selectedEvent.status === 'RESOLVED') && (
              <button className="primary-btn archive" onClick={() => archiveAndClose()}>
                <img src={archive} className="icon-filter-white" alt="archive" />
                Archive
              </button>
              )}
              {selectedEvent.source === 'video' && (
              <button
                disabled={!fragment}
                onClick={() => fetchClip()}
                className="primary-btn"
              >
                <img src={download} alt="download" />
                Download
              </button>
              )}
            </div>
          </div>
        </div>

        {commentList?.length
          ? (
            <table>
              <thead>
                <tr>
                  <th colSpan="2">Comment</th>
                </tr>
              </thead>
              <tbody>
                {commentList?.map((comment, index) => (
                  <tr key={index}>
                    <td>
                      <div className="bold mb-05">{comment?.commentedBy}</div>
                      {comment?.comment}
                    </td>
                    <td align="right" className="comment-time">{moment(comment?.commentedAt).format('DD MMM YYYY hh:mm a')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )
          : null}
      </div>
    </div>
  );
}

export default ViewEventPopup;
