/* eslint-disable */
import { Grid } from '@mui/material';
import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import signalFair from '../assets/signalFair.svg';
import signalGood from '../assets/signalGood.svg';
import signalStrong from '../assets/signalStrong.svg';
import signalWeak from '../assets/signalWeak.svg';
import noSignal from '../assets/noSignal.svg'
import { Role, SignalingClient } from 'amazon-kinesis-video-streams-webrtc';
import * as AWS from 'aws-sdk';
import { Hub, PubSub } from 'aws-amplify';
// import play from '../assets/play.svg';
import { getChannelARN, getEndpointsByProtocol, getIceServers } from '../services/kinesis';
import warning from '../assets/warning.svg';
import { getKinesisVideoCreds, subscribeToDeviceChanges } from '../api/UserAPI';
import randomString from '../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateVideoInfo } from '../redux/userInfo';

export default function LiveVideoStream(props) {
  const dispatch = useDispatch();
  const remoteVideoStream = useRef();
  const [loading, setLoading] = useState(false);
  const [isDataChannelAvailable, setDataChannelAvailable] = useState(false);
  const [, setDataChannel] = useState(null);
  const [, setMessageHistory] = useState('');
  const [, setError] = useState(null);
  const videoInfo = useSelector((state) => state.userInfo.videoInfo);
  const siteOwnerId = useSelector((state) => state.userInfo.ownerId);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const numberOfViewers = useRef();
  const rssi = useRef();
  const sd = useRef();
  // viewers subscriptions
  const viewersSubscription = useRef();
  // ['new', 'connecting', 'connected', 'disconnected', 'failed', 'closed'
  const [peerConnectionState, setPeerConnectionState] = useState(null);
  const [mqttConnectionRetrySignal, setMqttConnectionRetrySignal] = useState(0);
  const stream = useRef();
  const client = useRef();
  const connection = useRef();
  const channel = useRef();
  const camName = props?.device?.host?.components[0]?.name;
  const isConnected = props?.device?.isConnected;
  const disabled = props?.disabled;
  const SDRecording = props?.device?.config?.insert?.video?.storage?.local?.mode === 'continuous' 
  ? 'Continuous' : props?.device?.config?.insert?.video?.storage?.local?.mode === 'motion' 
  ? 'Event Only' : 'No SD Detected';

  useEffect(() => {
    Hub.listen("pubsub", (data) => {
      const { payload } = data;
        if (payload.data.connectionState === 'Disconnected' || payload.data.connectionState === 'ConnectionDisrupted' || payload.data.connectionState === 'ConnectionDisruptedPendingNetwork') {
          setMqttConnectionRetrySignal(mqttConnectionRetrySignal + 1);
        }
    });
    return () => {
      Hub.remove("pubsub");
    };
  }, [props.device, videoInfo]);

  useEffect(() => {
    if (props?.device?.deviceId && selectedSiteId) {
      if (viewersSubscription.current) {
        viewersSubscription.current.unsubscribe();
        viewersSubscription.current = null;
      }
      const subscription = subscribeToDeviceChanges(
        siteOwnerId,
        selectedSiteId,
        props?.device?.deviceId,
        (state) => {
          const { data } = state;
          if (!data) return;
          const { insert } = data;
          if (!insert) return;
          const deviceId = props?.device?.deviceId;
          numberOfViewers.current = insert?.video?.webrtc?.currentViewers;
          rssi.current = data?.connection?.rssi;
          sd.current = insert?.video?.sd?.mem;
          const newObj = {...videoInfo};
          newObj[deviceId] =  { viewers: insert?.video?.webrtc?.currentViewers, rssi: data?.connection?.rssi, sd: insert?.video?.sd?.mem} 
          dispatch(updateVideoInfo(newObj));

        },
        isConnected
      );
      viewersSubscription.current = subscription;
    }
    return () => {
      if (viewersSubscription?.current) {
        viewersSubscription.current.unsubscribe();
        viewersSubscription.current = null;
      }
    }
  }, [selectedSiteId, props.device.deviceId,  mqttConnectionRetrySignal]);

  const closeConnection = () => {
    if (connection.current) {
      connection.current.close();
    }
    if (client.current) {
      client.current.close();
    }
    if (channel.current) {
      channel.current.close();
    }
    setPeerConnectionState('closed');
  };

  const addMessageHistory = (message, isOutgoing) => {
    const now = new Date();
    const label = isOutgoing ? 'SENT' : 'RECEIVED';
    const history = `${now.toLocaleTimeString()} ${label} ${message}`;
    setMessageHistory((prev) => `${history}\n${prev}`);
  };

  const initWebRTC = useCallback(async () => {
    setPeerConnectionState('new');
    const { thingName } = props;
    const { isConnected } = props.device;
    const setupSignalingClientListeners = (signalingClient, peerConnection) => {
      signalingClient.on('open', async () => {
        peerConnection.addTransceiver('video', { direction: 'recvonly'} );
        peerConnection.addTransceiver('audio', { direction: 'recvonly'} );
        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);
        signalingClient.sendSdpOffer(peerConnection.localDescription);
      });

      signalingClient.on('sdpAnswer', async (answer) => {
        await peerConnection.setRemoteDescription(answer);
      });

      signalingClient.on('iceCandidate', (candidate) => {
        peerConnection.addIceCandidate(candidate);
      });

      signalingClient.on('close', () => {
      });

      signalingClient.on('error', (error) => {
        console.error(error);
      });
    };
    const setupDataChannelListeners = (dataChannel) => {
      dataChannel.onopen = () => {
        setLoading(false);
        setError(null);
        setDataChannelAvailable(true);
      };

      dataChannel.onmessage = (event) => {
        addMessageHistory(event.data, false);
      };

      dataChannel.onclose = () => {
        setLoading(false);
        setError('Data channel is closed');
        setDataChannelAvailable(false);
      };
    };
    const setupPeerConnectionListeners = (peerConnection, signalingClient) => {
      peerConnection.addEventListener('icecandidate', ({ candidate }) => {
        if (candidate) {
          signalingClient.sendIceCandidate(candidate);
        }
      });

      peerConnection.addEventListener('track', (event) => {
        remoteVideoStream.current.srcObject = event.streams[0];
      });
    };
    if (!isConnected) return;
    if (client.current) {
      client.current.close();
    }
    if (connection.current) {
      connection.current.close();
    }
    if (channel.current) {
      channel.current.close();
    }
    setLoading(true);
    const creds = await getKinesisVideoCreds(props.device.siteId, props.device.deviceId);
    const kinesisVideo = new AWS.KinesisVideo({
      credentials: {
        accessKeyId: creds.credentials.accessKeyId,
        secretAccessKey: creds.credentials.secretAccessKey,
        sessionToken: creds.credentials.sessionToken,
      },
      region: AWS.config.region,
    },);
    const channelARN = await getChannelARN(thingName, kinesisVideo);
    const endpointsByProtocol = await getEndpointsByProtocol(
      channelARN,
      kinesisVideo,
      Role.VIEWER,
    );
    const iceServers = await getIceServers(channelARN, endpointsByProtocol, props.device.deviceId, props.device.siteId);
    const peerConnection = new RTCPeerConnection({ iceServers });
    peerConnection.onconnectionstatechange = (event) => {
      setPeerConnectionState(peerConnection.connectionState);
    };
    const dataChannel = peerConnection.createDataChannel('kvsDataChannel');
    setupDataChannelListeners(dataChannel);
    setDataChannel(dataChannel);
    // const awsTempCredentials = AWS.config.credentials;
    // const awsTempCredentials = await Auth.Credentials.get();
    const signalingClient = new SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol.WSS,
      clientId: randomString(8),
      role: Role.VIEWER,
      region: 'us-east-1',
      credentials: {
        accessKeyId: creds.credentials.accessKeyId,
        secretAccessKey: creds.credentials.secretAccessKey,
        sessionToken: creds.credentials.sessionToken,
      },
      systemClockOffset: kinesisVideo.config.systemClockOffset,
    });
    setupSignalingClientListeners(signalingClient, peerConnection);
    setupPeerConnectionListeners(peerConnection, signalingClient);
    client.current = signalingClient;
    connection.current = peerConnection;
    channel.current = dataChannel;
    signalingClient.open();
    return () => {
      signalingClient.removeAllListeners('close');
      dataChannel.onclose = null;
      dataChannel.close();
      peerConnection.onconnectionstatechange = null;
      peerConnection.close();
      signalingClient.close();
    };
  }, [props.thingName, props.device.isConnected]);

  useEffect(() => {
    if (remoteVideoStream.current === null) {
      return;
    }
    if (!stream.current) {
      return;
    }
    remoteVideoStream.current.srcObject = stream.current;
  }, [remoteVideoStream]);

  useEffect(() => () => {
    if (connection.current) {
      connection.current.close();
    }
  }, [connection]);

  useEffect(() => () => {
    if (client.current) {
      client.current.removeAllListeners('close');
      client.current.close();
    }
  }, [client]);

  useEffect(() => () => {
    if (channel.current) {
      channel.current.onclose = null;
      channel.current.close();
    }
  }, [channel]);

  useEffect(() => {
    if (!disabled) {
      initWebRTC();
    }
  }, [initWebRTC, disabled]);

  const getVideoBtnWrapper = () => {
    if (isConnected && !disabled && ['disconnected', 'failed', 'closed'].includes(peerConnectionState)) {
      return (
        <>
          <span>
            {peerConnectionState.charAt(0).toUpperCase() + peerConnectionState.slice(1)}
            ...
          </span>
          <button className="text-btn" onClick={initWebRTC}>Retry</button>
        </>
      );
    } if (loading) {
      return (
        <>
          <span>Connecting...</span>
          <button className="text-btn" onClick={closeConnection}>Cancel</button>
        </>
      );
    }
    return null;
  };

  const getRSSIIcon = () => {
    let img;
    switch (true) {
      case rssi.current < -110:
        img = <img src={noSignal} className='signal-img'/>;
        break;
      case rssi.current >= -110  && rssi.current < -100 :
        img = <img src={signalWeak} className='signal-img'/>;
        break;
      case rssi.current >= -100  && rssi.current <= -86 :
        img = <img src={signalFair} className='signal-img'/>;
        break;
      case rssi.current >= -85 && rssi.current < -70 :
        img = <img src={signalGood}className='signal-img'/>;
        break;
      case rssi.current >= -70: 
        img = <img src={signalStrong} className='signal-img'/>;
        break;
      default: 
        img =<img src={noSignal} className='signal-img'/>

    }
    return img;
  };

  const getMemeory = () => {
    const mem = isNaN(sd.current?.total) ? 0 : Math.round(sd.current?.total /Math.pow(10, 6));
    const roundedMem = Math.pow(2,Math.ceil(Math.log(mem)/Math.log(2)));
    return `${roundedMem} GB`;
  }

  return (
    <Grid item xs={6}>
      <div className="container">
      {isConnected
        ? (
      <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <h3 style={{ display: 'inline-block' }}>{camName}</h3>
        {peerConnectionState === 'connected' && <button className="text-btn primary" style={{ marginLeft: '8px' }} onClick={closeConnection}>[X]</button>}
      </div>
        )
        :  (
          <h3 className="cam-name">
            <img src={warning} alt="disconnected" className="icon-filter-yellow" />
            {camName}
            {' '}
            - Offline
          </h3>
        )}
        <video
          autoPlay={isConnected && !disabled}
          controls={isConnected && !disabled}
          ref={remoteVideoStream}
          style={{ width: '100%', backgroundColor: '#000000' }}
        />

        <div className="video-btn-wrapper">
          {getVideoBtnWrapper()}
        </div>
      </div>

      {/* Name of insert chosen by user */}
      {isConnected
        ? (
          <div className='video-info'>
            <div >
              <h5 className='black'><b>Viewers: </b>{`${numberOfViewers.current ?? 0}`}</h5>
              <h5 className='black'><b>RSSI: </b>{`${rssi.current ?? ''} `}{getRSSIIcon()}</h5>
            </div>
            <div>
              <h5 className='black'><b>SD Recording: </b>{` ${SDRecording}`}</h5>
              <h5 className='black'><b>Total Memory: </b>{getMemeory()}</h5>
            </div>
          </div>
        )
        : null }
    </Grid>
  );
}
