/* eslint-disable */
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { PubSub, Amplify, Auth } from 'aws-amplify';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import * as AWS from 'aws-sdk';
import { isLoggedIn, signOut } from './api/AuthAPI';
import LoginPage from './components/LoginPage';
import { resetState } from './redux/userInfo';
import randomString from './utils/utils';

const ProtectedRoutes = (props) => {
  const [isAuth, setAuth] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const loggedInCallback = useCallback(async () => {
    try {
      const data = await isLoggedIn();
      setAuth(data);
      if (data) {
        const session = await Auth.Credentials.get();
        AWS.config.credentials = {
          sessionToken: session.sessionToken,
          secretAccessKey: session.secretAccessKey,
          accessKeyId: session.accessKeyId,
          region: 'us-east-1',
        };
        AWS.config.region = 'us-east-1';
        const userId = localStorage.getItem('userId');
        if (Amplify.PubSub._pluggables.length === 0 && userId !== null) {
          Amplify.addPluggable(new AWSIoTProvider({
            aws_pubsub_region: process.env.REACT_APP_REGION,
            aws_pubsub_endpoint: process.env.REACT_APP_IOT_ENDPOINT,
            clientId: localStorage.getItem('userId') + randomString(10),
          }));
        }
      }
    } catch (error) {
      console.log('MISSED THIS ERR', error);
      setAuth(false);
    }
  }, []);

  useEffect(() => {
    loggedInCallback();
  }, [isAuth, loggedInCallback]);

  
  const loginHandler = () => {
    console.log('AUTH IS', true);
    setAuth(true);
  };

  const logoutHandler = async () => {
    await signOut();
    setAuth(false);
    PubSub.removePluggable('AWSIoTProvider');
    localStorage.removeItem('audience');
    localStorage.removeItem('userId');
    dispatch(resetState(''));
    navigate('/');
    // location.reload();
  };
  const getComponentToRender = (isAuth) => {
    if (isAuth === null) {
      return <LinearProgress color="inherit" />;
    } if (isAuth === false) {
      navigate('/');
      return <LoginPage loginHandler={loginHandler} />;
    }
    return React.Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { logoutHandler });
      }
      return child;
    });
  };
  // console.log("LAST AUTH VALUE", isAuth);

  return getComponentToRender(isAuth);
};

export default ProtectedRoutes;
