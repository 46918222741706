import React from 'react';
import { Radio } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

function RadioButton(props) {
  let color = '';
  const audience = useSelector((state) => state.userInfo.audience);
  if (audience === 'first_key') {
    color = '#52C4E9';
  } else if (audience === 'progress_residential') {
    color = '#ED7534';
  } else if (audience === 'stonecrest') {
    color = '#137CAF';
  } else if (audience === 'rogers_business') {
    color = '#da291c';
  } else {
    color = '#D8095F';
  }

  const RBIcon = styled('span')(() => ({
    borderRadius: '50%',
    width: 12,
    height: 12,
    backgroundColor: 'white',
  }));

  const RBCheckedIcon = styled(RBIcon)({
    backgroundColor: color,
  });

  const controlProps = (item) => ({
    checked: props.selectedValue === item,
    onChange: props.handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });
  return (
    <div className="radio-button-container ">
      <Radio
        icon={<RBIcon />}
        checkedIcon={<RBCheckedIcon />}
        {...controlProps(props.value)}
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: 15,
          },
        }}
      />
      <label>{props.value}</label>
    </div>
  );
}
export default RadioButton;
