/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DisplayTileIcon from './DisplayTileIcon';
import { updateSensor } from './Update';

function TileComponent(props) {
  const dispatch = useDispatch();
  const [rightClick, setRightClick] = useState(false);
  const [contextBoxPoints, setContextBoxPoints] = useState({ x: 0, y: 0 });
  const [newClass, setNewClass] = useState('');
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);

  useEffect(() => {
    if (props.installationStatus !== '') {
      if (props.installationStatus?.value === 'uninstalled' && props.status === 'Offline') setNewClass('uninstalled');
    }
  }, []);

  const showMenu = (event) => {
    event.preventDefault();
    setRightClick(true);
    setContextBoxPoints({
      x: event.clientX,
      y: event.clientY,
    });
  };

  const changeState = () => {
    setRightClick(false);
  };

  const specifiedElement = document.getElementById('right-click-options');
  document.addEventListener('click', (event) => {
    if (specifiedElement) {
      const isClickInside = specifiedElement.contains(event.target);
      if (!isClickInside) {
        setRightClick(false);
      }
    }
  });

  const chooseState = (state) => {
    if (state === 'uninstalled' && props.status === 'Offline') setNewClass('uninstalled');
    else setNewClass('');
    updateSensor(dispatch, selectedSiteId, props.deviceId, state);
  };

  return (
    <div className={`tile ${newClass} ${props.className}`} onContextMenu={(e) => showMenu(e)}>
      {rightClick
      && (
      <div id="right-click-options" className="tile-context-menu" style={{ position: 'fixed', top: `${contextBoxPoints.y}px`, left: `${contextBoxPoints.x}px` }} onClick={changeState}>
        <ul>
          <li onClick={() => chooseState('uninstalled')}>Set as Not Installed</li>
          <li onClick={() => chooseState('installed')}>Set as Installed</li>
        </ul>
      </div>
      )}
      <h6>
        {props.name}
      </h6>
      <DisplayTileIcon icon={props.icon} />
      {newClass !== 'uninstalled' && <span>{props.status}</span>}
      {props.img
      && newClass !== 'uninstalled' && <div><img src={props.ImgSource} alt={props.imgAlt} className={props.imgClassName} /></div>}
    </div>
  );
}

export default TileComponent;
