import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateRoomSettings } from './Update';
import Toggle from './Toggle';

function AlertsToggle() {
  const dispatch = useDispatch();
  const roomList = useSelector((state) => state.userInfo.roomList);
  const selectedRoomId = useSelector((state) => state.userInfo.selectedRoomId);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const [modifier, setModifier] = useState('');
  const [selectedRoom, setSelecetedRoom] = useState(
    roomList.rooms.find((room) => room.roomId === selectedRoomId),
  );
  const [toggleState, setToggleState] = useState(selectedRoom.settings.alerts.isSilent);
  const toggleAlertsState = () => {
    const response = updateRoomSettings(dispatch, selectedSiteId, selectedRoomId, !toggleState);
    if (response) setToggleState(!toggleState);
  };

  useEffect(() => {
    setSelecetedRoom(roomList.rooms.find((room) => room.roomId === selectedRoomId));
    setToggleState((selectedRoom.settings.alerts.isSilent));
    setModifier(selectedRoom.changeLogs.find((log) => log.attribute === 'settings.alerts.isSilent')?.modifiedBy.name ?? 'None');
  }, [selectedRoomId, roomList, selectedRoom]);

  return (
    <div className="alert-toggle-conatiner">
      <Toggle text="Disable Alerts" toggleStateFunction={toggleAlertsState} toggleState={toggleState} />
      <p className="modifier-container">
        <b>Modified by: </b>
        {modifier}
      </p>
    </div>
  );
}

export default AlertsToggle;
