/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import ScheduleDatePicker from './ScheduleDatePicker';
import ScheduleTimePicker from './ScheduleTimePicker';
import deleteImg from '../assets/delete.svg';
import { validateTime } from './Update';

function Exceptions(props) {
  const exceptionState = useRef();
  const [clear, setClear] = useState(false);
  const [exceptionError, setExceptionError] = useState(false);

  const handleChangeException = (newValue, name) => {
    setExceptionError(false);
    const value = name === 'date' ? moment(newValue.$d).format('MM-DD-YYYY') : moment(newValue._d).format('HH:mm');
    exceptionState.current = { ...exceptionState.current, [name]: value };
  };

  useEffect(() => {
    if (clear) exceptionState.current = { date: '', startTime: '', endTime: '' };
    setClear(false);
  }, [clear]);

  const addException = (startTime, endTime, date, exceptionError) => {
    if (startTime && endTime && date && !exceptionError) {
      if (validateTime(startTime, endTime)) {
        setExceptionError(false);
        props.setChangesMade(true);

        const exObject = {
          date, // MM-DD-YYYY
          timeSlots: [{
            startTime, // 24 hour format
            endTime, // 24 hour format
          }],
        };

        // replace state with new array that contains all old items & one new item at end
        props.setExList(
          [
            ...props.exList,
            exObject,
          ],
        );
        setClear(true);
      } else {
        setExceptionError(true);
      }
    } else {
      console.log('MISSING VALUES');
    }
  };

  const deleteException = (e) => {
    props.setChangesMade(true);
    const chosen = e.target.parentElement.id;
    props.setExList(
      props.exList.filter((exception) => exception.date !== chosen),
    );
  };

  return (
    <div className="exceptions-container">
      <h3 className="black mb-05">Exceptions</h3>
      {props.exList?.length
        ? (
          <ul className="exception-wrapper mb-2">
            {props.exList.map((exception) => (
              <li key={exception.date} id={exception.date}>
                {exception?.date}
                {' '}
                from&nbsp;
                {exception?.timeSlots[0]?.startTime}
                {' '}
                to&nbsp;
                {exception?.timeSlots[0]?.endTime}
                <img src={deleteImg} alt="delete" onClick={deleteException} className="delete" />
              </li>
            ))}
          </ul>
        )
        : (
          <div className="mb-2">
            <p>No exceptions</p>
          </div>
        )}
      <div className="add-exception-wrapper">
        <div>
          <h4 className="black">Date</h4>
          <ScheduleDatePicker value={exceptionState.current?.date} onChange={(value) => handleChangeException(value, 'date')} />
        </div>
        <div>
          <h4 className="black">Start Time</h4>
          <ScheduleTimePicker value={exceptionState.current?.startTime} onChange={(value) => handleChangeException(value, 'startTime')} />
        </div>
        <div>
          <h4 className="black">End Time</h4>
          <ScheduleTimePicker value={exceptionState.current?.endTime} onChange={(value) => handleChangeException(value, 'endTime')} />
        </div>
      </div>
      { exceptionError && <p className="error">Error: End time should be after start time</p>}
      <h4 className="header-link" onClick={() => addException(exceptionState.current?.startTime, exceptionState.current?.endTime, exceptionState.current?.date, exceptionError)}>+Add another exception</h4>
    </div>
  );
}

export default Exceptions;
