import React from 'react';

function Toggle(props) {
  return (
    <div className="mr-1">
      <label className="switch">
        <input
          type="checkbox"
          checked={props.toggleState}
          onChange={() => props.toggleStateFunction()}
        />
        <span className="slider" />
      </label>
      <span className="white alerts-toggle">{props.text}</span>
    </div>
  );
}
export default Toggle;
