import React from 'react';
import warning from '../assets/warning.svg';
import DisplayDeviceContent from './DisplayDeviceContent';
import TileComponent from './TileComponent';

function StatusBar(props) {
  // let cameraList = props.cameraList;
  const { deviceList } = props;
  const { selectedRoomId } = props;

  // list of cameras
  const cameraList = deviceList?.devices?.filter(
    (device) => device?.roomId === selectedRoomId && device?.insert?.type.toLowerCase() === 'video',
  );

  // display offline cameras
  function displayOfflineCameras() {
    const offlineCameras = cameraList?.filter(
      (device) => device?.isConnected === false,
    );

    return (
      offlineCameras?.length
        ? offlineCameras?.map((device) => (
          <TileComponent installationStatus={device.tags ? device?.tags.find((tag) => tag.key === 'installationStatus') ?? '' : ''} className="offline highlight" name={device?.host?.components[0]?.name} icon="video" status="Offline" img ImgSource={warning} imgAlt="offline" imgClassName="warning" deviceId={device?.deviceId} />
        ))
        : null
    );
  }

  // display offline sensors
  function displayOfflineSensors() {
    const offlineSensors = deviceList?.devices?.filter(
      (device) => device?.roomId === selectedRoomId
          && device?.isConnected === false
          && !device?.insert?.functions.includes('video'),
    );
    return (
      offlineSensors?.length
        ? offlineSensors?.map((device) => (
          <DisplayDeviceContent key={device.deviceId} device={device} />
        ))
        : null
    );
  }

  // display occupied motion sensors in status bar
  function displayOccupiedSensors() {
    const occupiedSensors = deviceList?.devices?.filter(
      (device) => device?.roomId === selectedRoomId
          && device?.isConnected === true
          && device?.insert?.functions.includes('motion'),
    );

    return (
      occupiedSensors?.length
        ? occupiedSensors?.map((device) => (
          <DisplayDeviceContent key={device.deviceId} device={device} onlyOccupied />
        ))
        : null
    );
  }

  return (
    <div className="right">
      <div className="tile-wrapper-2">

        {props.activeAlertsCount
          ? (
            <TileComponent className="tile alert highlight" name="Alerts" status={props.activeAlertsCount} icon="alert" img={false} />
          )
          : null}
        {displayOfflineCameras()}
        {displayOfflineSensors()}
        {displayOccupiedSensors()}
      </div>
    </div>
  );
}

export default StatusBar;
