import React from 'react';
import { useDispatch } from 'react-redux';
import Dropdown from './Dropdown';

import { updateAlertsOrder } from '../redux/userInfo';
import EventsTable from './EventsTable';

function ContentContainer(props) {
  // const alertsOrder = useSelector((state) => state.userInfo.alertsOrder);

  const dispatch = useDispatch();
  const mainAlertOptions = ['Most Recent', 'Oldest - Newest'];

  const updateSortOption = (index) => {
    index === 0 ? dispatch(updateAlertsOrder('desc')) : dispatch(updateAlertsOrder('asc'));
  };

  return (
    <div className="content">
      <div className="sub-header dash-tab mb-1">
        <div className="left">
          <h1>Alerts</h1>
          <div className="right">
            <div className="filters">
              <h4 className={`tab ${props.tabName === 'Unread Alerts' ? 'selected' : ''}`} onClick={() => props.changeTab('Unread')}>Unread</h4>
              <h4 className={`tab ${props.tabName === 'Active Alerts' ? 'selected' : ''}`} onClick={() => props.changeTab('Active')}>Active</h4>
            </div>
            <Dropdown label="Sort by: " options={mainAlertOptions} updateOption={updateSortOption} />

          </div>
        </div>
      </div>
      <EventsTable
        list={props.list}
        setAlertLoading={props.setAlertLoading}
        setSelectedEvent={props.setSelectedEvent}
        updateScrollState={props.updateScrollState}
      />

    </div>
  );
}

export default ContentContainer;
