import React, { useState } from 'react';
import './LoginSignupPage.css';
import { useNavigate } from 'react-router-dom';
import { confirmSignUp, resendVerificationCode } from '../api/AuthAPI';

function VerifyAccount(props) {
  console.log('in verification component', props);
  const navigate = useNavigate();
  const [code, setCode] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const handleChange = (event) => {
    setCode(event.target.value);
  };
  const handleSubmit = async () => {
    const response = await confirmSignUp({ username: props.username, confirmationCode: code });
    if (response) navigate('/');
  };

  const hanldeResendCode = async () => {
    const response = await resendVerificationCode({ username: props.username });
    console.log(response);
    if (response) {
      setShowMessage(true);
    }
  };

  return (
    <div className="outer-container">
      <div className="inner-container center">
        <img src="https://cdn.shopify.com/s/files/1/0256/7900/3726/files/Logo_pink_horizontal-notagline.png?v=1654708500" alt="Swidget logo" />
        <p><b>Two-factor Authentication Required</b></p>
        <p className="text-center">Enter the authentication code that was emailed to you in the field below</p>
        <input placeholder="Enter Code" className="code" onChange={handleChange} />
        <div className="btn-wrapper">
          <input type="submit" value="Submit" className="swidget-pink-btn centered" onClick={handleSubmit} />
        </div>
        {showMessage && <p className="mt-1">Code has been sent to your email</p>}
        <h4 className="header-link pink" onClick={hanldeResendCode}>Resend Verification Code</h4>
      </div>
    </div>
  );
}
export default VerifyAccount;
