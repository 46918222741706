import { configureStore } from '@reduxjs/toolkit';
import userInfoReducer from './userInfo';

const state = configureStore({
  reducer: {
    userInfo: userInfoReducer,
  },
  preloadedState: localStorage.getItem('dashboard-state') ? JSON.parse(localStorage.getItem('dashboard-state')) : undefined,
});

state.subscribe(() => {
  localStorage.setItem('dashboard-state', JSON.stringify(state.getState()));
});

export default state;
