import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from './FormatStuff';
import Dropdown from './Dropdown';
import { seperateArchived } from './Update';
import RadioButton from './RadioButton';
import { getRoomInfo } from '../api/UserAPI';
import comment from '../assets/comment.svg';
import { updateCommentList, updateCommentType } from '../redux/userInfo';
import CommentPopup from './CommentPopup';

function PropertiesTab() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mainAlertOptions = ['Status', 'Street', 'City', 'Zip Code'];
  const roomList = useSelector((state) => state.userInfo.roomList);
  // list of archived property id's
  const archivedList = useSelector((state) => state.userInfo.archivedList);
  const assignedKits = useSelector((state) => state.userInfo.assignedKits);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const [roomsSeperatedArchived, setRoomsSeperateArchived] = useState([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [displayedRooms, setDisplayedRooms] = useState([]);
  const [sortByStreet, setSortByStreet] = useState([]);
  const [sortByCity, setSortByCity] = useState([]);
  const [sortByZipCode, setSortByZipCode] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [selectedProperty, setSelectedPorperty] = useState();
  const [showComments, setShowComments] = useState(false);
  const sites = useSelector((state) => state.userInfo.sites);
  const selectedSite = sites ? sites.find((site) => site.siteId === selectedSiteId) : {};
  const timeZone = selectedSite?.config?.tz?.id;

  const handleChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const updateSortOption = (index) => {
    setSelectedOptionIndex(index);
  };

  useEffect(() => {
    if (selectedOptionIndex === 0) setDisplayedRooms(roomsSeperatedArchived);
    else if (selectedOptionIndex === 1) {
      setDisplayedRooms(sortByStreet);
    } else if (selectedOptionIndex === 2) {
      setDisplayedRooms(sortByCity);
    } else if (selectedOptionIndex === 3) {
      setDisplayedRooms(sortByZipCode);
    }
  }, [selectedOptionIndex, displayedRooms,
    roomsSeperatedArchived, sortByStreet, sortByCity, sortByZipCode]);

  const updateRoomListInfo = (rooms) => {
    const promises = rooms.map(async (room) => {
      const response = await getRoomInfo(selectedSiteId, room.roomId);
      let archivedDate = null;
      if (response.room.eventLogs.length > 0) {
        const lastLog = response.room.eventLogs[0];
        if (lastLog?.type === 'room_archived') archivedDate = lastLog?.createdAt;
      }
      const stringArray = room.name.split(',');
      const street = stringArray[0].replace(/\d+/g, '');
      const addressDetailsObject = { street: street ?? '', city: stringArray[1] ?? '', zipCode: stringArray[stringArray.length - 1] ?? '' };
      if (archivedList.includes(room.roomId)) {
        return {
          ...room, status: 'archived', kit: [], address: addressDetailsObject, archivedDate,
        };
      }
      return {
        ...room, status: 'unarchived', kit: [], address: addressDetailsObject, archivedDate,
      };
    });
    return Promise.all(promises);
  };

  const updateDisplayedRooms = async () => {
    if (roomList.rooms) {
      let rooms = [];
      if (selectedFilter === 'All') rooms = roomList.rooms;
      else {
        const archived = [];
        const active = [];
        roomList.rooms.map((room) => {
          if (archivedList.includes(room.roomId)) archived.push(room);
          else active.push(room);
        });
        if (selectedFilter === 'Active') rooms = active;
        if (selectedFilter === 'Archived') rooms = archived;
      }
      const propertiesList = await updateRoomListInfo(rooms);
      propertiesList.map((property) => {
        if (assignedKits) {
          assignedKits.forEach((kit) => {
            if (kit.roomId === property.roomId) property.kit.push(kit.name);
          });
        }
      });
      propertiesList
        ? setRoomsSeperateArchived(seperateArchived(propertiesList, archivedList))
        : setRoomsSeperateArchived([]);
      propertiesList
        ? setSortByStreet(propertiesList.slice().sort(
          (firstElement, secondElement) => firstElement.address?.street.localeCompare(
            secondElement.address?.street,
          ),
        ))
        : setSortByStreet([]);
      propertiesList
        ? setSortByCity(propertiesList.slice().sort(
          (firstElement, secondElement) => firstElement.address?.city.localeCompare(
            secondElement.address?.city,
          ),
        ))
        : setSortByCity([]);
      propertiesList
        ? setSortByZipCode(propertiesList.slice().sort(
          (firstElement, secondElement) => firstElement.address?.zipCode.localeCompare(
            secondElement.address?.zipCode,
          ),
        ))
        : setSortByZipCode([]);
    } else {
      setRoomsSeperateArchived([]);
      setSortByStreet([]);
      setSortByCity([]);
      setSortByZipCode([]);
    }
  };

  useEffect(() => {
    updateDisplayedRooms();
  }, [roomList, assignedKits, selectedFilter]);

  // open comment popup
  const openComments = (event, property) => {
    if (property?.comments?.length > 0) {
      property?.comments.forEach((comment) => dispatch(updateCommentList(comment)));
    }
    setSelectedPorperty(property);
    dispatch(updateCommentType('property'));
    setShowComments(true);
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  return (
    <div className="content">
      <div className="sub-header dash-tab mb-1">
        <div className="left">
          <h1>Properties</h1>
        </div>
        {showComments
        && (
          <CommentPopup
            selectedProperty={selectedProperty}
            closePopup={() => setShowComments(false)}
          />
        )}
        <div className="right properties">
          <div className="radio-button-group">
            <RadioButton default value="All" selectedValue={selectedFilter} handleChange={handleChange} />
            <RadioButton default value="Active" selectedValue={selectedFilter} handleChange={handleChange} />
            <RadioButton default value="Archived" selectedValue={selectedFilter} handleChange={handleChange} />
          </div>
          <Dropdown label="Sort by: " options={mainAlertOptions} updateOption={updateSortOption} />

        </div>
      </div>

      {displayedRooms.length
        ? (
          <div className="scrollable-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Address</th>
                  <th>Date Added</th>
                  <th>Kit Number</th>
                  <th>Archived</th>
                  <th>Date Archived</th>
                  <th>Notes</th>
                  <th aria-label="comment-icon" />
                </tr>
              </thead>
              <tbody>
                {displayedRooms.map((property) => (
                  <tr key={property.roomId} className={property?.status === 'archived' ? 'grey' : 'white'}>
                    <td onClick={() => navigate(`market/${selectedSiteId}/property/${property.roomId}/activitylog`)} className="room-link">{property.name}</td>
                    <td>{formatDate(property.createdAt, timeZone)}</td>
                    <td style={{ display: 'flex', flexDirection: 'column' }}>
                      { property.kit
                        ? property.kit.map((name, index) => <span key={index}>{name}</span>)
                        : ''}
                    </td>
                    <td>{`${property?.status === 'archived' ? 'Archived' : ''}`}</td>
                    <td>{`${property?.archivedDate === null ? '' : formatDate(property?.archivedDate)}`}</td>
                    {property.comments?.length > 0
                      ? <td>{property.comments[0].comment}</td>
                      : <td aria-label="comments" />}
                    <td className="alert-table-icons">
                      <button className="comment" onClick={(event) => openComments(event, property)}>
                        <div className={`bubble-wrapper
                          ${property?.comments?.length > 0 ? 'visible' : ''}`}
                        >
                          <FontAwesomeIcon icon={faCircle} className="comment-bubble" />
                          <span>{property?.comments?.length}</span>
                        </div>
                        <img src={comment} alt="comment" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
        : (
          <div className="white-wrapper">
            <p>No Properties</p>
          </div>
        )}

    </div>
  );
}

export default PropertiesTab;
