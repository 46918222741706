import React, { useState, useEffect } from 'react';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import close from '../assets/close.svg';
import Dropdown from './Dropdown';
import { createRoom } from '../api/UserAPI';
import { updateSites } from './Update';

function AddPropertyPopup(props) {
  const dispatch = useDispatch();
  const sites = useSelector((state) => state.userInfo.sites);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const [addPropError, setAddPropError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedSiteOptionIndex, setSelectedSiteOptionIndex] = useState(
    sites.findIndex((site) => site.siteId === selectedSiteId),
  );
  const [selectedCity, setSelectedCity] = useState({
    chosenName: props.selectedSiteName,
    chosenId: props.selectedSiteId,
  });

  const saveProperty = async () => {
    const houseNum = document.getElementById('house-num');
    const street = document.getElementById('street');
    const city = document.getElementById('city');
    const state = document.getElementById('state');
    const zipCode = document.getElementById('zip-code');
    let address = '';

    if (houseNum?.value === '') {
      setAddPropError(true);
      setErrorMsg('House number is a required field');
      houseNum.focus();
    } else if (street?.value === '') {
      setAddPropError(true);
      setErrorMsg('Street is a required field');
      street.focus();
    } else if (city?.value === '') {
      setAddPropError(true);
      setErrorMsg('City is a required field');
      street.focus();
    } else if (state?.value === '') {
      setAddPropError(true);
      setErrorMsg('State is a required field');
      street.focus();
    } else {
      setAddPropError(false);
      setErrorMsg('');

      if (zipCode?.value === '') {
        address = `${houseNum.value} ${street.value}, ${city.value}, ${state.value}`;
      } else {
        address = `${houseNum.value} ${street.value}, ${city.value}, ${state.value}, ${zipCode.value}`;
      }

      const response = await createRoom(selectedCity?.chosenId, address);

      if (response?.success) {
        props.switchSite(selectedCity?.chosenName, selectedCity?.chosenId);
        updateSites(dispatch);
        props.setState({ addPropertyPopup: false });
      } else {
        setAddPropError(true);
        setErrorMsg('Error: Property could not be added');
      }
    }
  };

  useEffect(() => {
    setSelectedCity(
      {
        chosenName: sites[selectedSiteOptionIndex]?.name,
        chosenId: sites[selectedSiteOptionIndex]?.siteId,
      },
    );
  }, [selectedSiteOptionIndex]);

  const updateSiteOption = (index) => {
    setSelectedSiteOptionIndex(index);
  };
  return (
    <div id="add-property-popup" className="popup">
      <div className="popup-content">
        <img src={close} alt="close" className="close-btn" onClick={() => props.setState({ addPropertyPopup: false })} />
        <h2 className="black mb-2">Add New Property</h2>

        <div className="input-group">
          {addPropError
            ? (
              <div className="error-message">
                <FontAwesomeIcon icon={faTriangleExclamation} />
                {' '}
                {errorMsg}
              </div>
            )
            : null}

          <input type="text" id="house-num" placeholder="House number*" autoFocus />
          <input type="text" id="street" placeholder="Street*" />
          <input type="text" id="city" placeholder="City*" />
          <input type="text" id="state" placeholder="State*" />

          {sites ? (
            <Dropdown
              updateOption={updateSiteOption}
              options={sites.map((site) => site.name)}
              options2={sites.map((site) => (site.roomCount > 1 ? `${site.roomCount} properties` : `${site.roomCount} property`))}
              className="dropdown-btn large"
              firstRowTag="span"
              firstSelectedOptionIndex={sites.findIndex((site) => site.siteId === selectedSiteId)}
            />
          )
            : null}
          <input type="text" id="zip-code" placeholder="Zip code" className="mb-05" />
          <button className="primary-btn centered" onClick={saveProperty}>Save</button>
        </div>
      </div>
    </div>
  );
}
export default AddPropertyPopup;
