import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatDate } from './FormatStuff';
import RadioButton from './RadioButton';
import Dropdown from './Dropdown';
import { customSortFunction } from './Update';
import deleteImg from '../assets/delete.svg';
import Popup from './Popup';
import DeleteKitComponent from './DeleteKitComponent';

function KitsTab() {
  const assignedKits = useSelector((state) => state.userInfo.assignedKits);
  const unassignedKits = useSelector((state) => state.userInfo.unassignedKits);
  const roomList = useSelector((state) => state.userInfo.roomList);
  const [kitsWithAddress, setKitsWithAddress] = useState([]);
  const mainAlertOptions = ['Date Assigned', 'Date Unassigned', 'Alphabetical'];
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [assignedKitsWithAddress, setAssignedKitsWithAddress] = useState([]);
  const [unassignedKitsWithAddress, setUnassigedKitsWithAddress] = useState([]);
  const [kitsSortedByAssignedDate, setKitsSortedByAssginedDate] = useState([]);
  const [kitsSortedByUnassignedDate, setKitsSortedByUnassginedDate] = useState([]);
  const [kitsSortedAlphabetically, setKitsSortedAlphabetically] = useState([]);
  const [displayedKits, setDisplayedKits] = useState([]);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const sites = useSelector((state) => state.userInfo.sites);
  const selectedSite = sites ? sites.find((site) => site.siteId === selectedSiteId) : {};
  const timeZone = selectedSite?.config?.tz?.id;
  const [showPopup, setShowPopup] = useState(false);
  const [selectedKit, setSelectedKit] = useState({});

  const deleteSelectedKit = async () => {
    setShowPopup(true);
  };

  const handleChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const updateSortOption = (index) => {
    setSelectedOptionIndex(index);
  };

  useEffect(() => {
    if (assignedKits) {
      setAssignedKitsWithAddress(assignedKits.map((kit) => {
        const room = roomList?.rooms?.find((room) => room.roomId === kit.roomId);
        if (room) return { ...kit, address: room.name.split(',')[0] };
      }));
    }
    if (unassignedKits) setUnassigedKitsWithAddress(unassignedKits.map((kit) => ({ ...kit, address: '' })));
  }, [roomList, assignedKits, unassignedKits]);

  useEffect(() => {
    if (selectedFilter === 'Assigned') setKitsWithAddress(assignedKitsWithAddress);
    else if (selectedFilter === 'Unassigned') setKitsWithAddress(unassignedKitsWithAddress);
    else setKitsWithAddress(assignedKitsWithAddress.concat(unassignedKitsWithAddress));
  }, [selectedFilter, assignedKitsWithAddress, unassignedKitsWithAddress]);

  useEffect(() => {
    kitsWithAddress
      ? setKitsSortedAlphabetically(
        kitsWithAddress.slice().sort(
          (firstElement, secondElement) => firstElement.name.localeCompare(
            secondElement.name,
          ),
        ),
      )
      : setKitsSortedAlphabetically([]);
    kitsWithAddress
      ? setKitsSortedByAssginedDate(kitsWithAddress.slice().sort((firstElement, secondElement) => customSortFunction(firstElement.lastAssignedAt, secondElement.lastAssignedAt, 'des')))
      : setKitsSortedByAssginedDate([]);
    kitsWithAddress
      ? setKitsSortedByUnassginedDate(kitsWithAddress.slice().sort((firstElement, secondElement) => customSortFunction(firstElement.lastUnassignedAt, secondElement.lastUnassignedAt, 'des')))
      : setKitsSortedByUnassginedDate([]);
  }, [kitsWithAddress, assignedKits, unassignedKits]);

  useEffect(
    () => {
      if (selectedOptionIndex === 0) setDisplayedKits(kitsSortedByAssignedDate);
      else if (selectedOptionIndex === 1) setDisplayedKits(kitsSortedByUnassignedDate);
      else if (selectedOptionIndex === 2) setDisplayedKits(kitsSortedAlphabetically);
    },
    [selectedOptionIndex,
      displayedKits,
      kitsWithAddress,
      kitsSortedAlphabetically,
      kitsSortedByAssignedDate,
      kitsSortedByUnassignedDate],
  );

  return (
    <div className="content">
      <div className="sub-header kit-tab dash-tab mb-1">
        <div className="left">
          <h1>Kits</h1>
        </div>
        <div className="right kit">
          <div className="radio-button-group">
            <RadioButton default value="All" selectedValue={selectedFilter} handleChange={handleChange} />
            <RadioButton default value="Assigned" selectedValue={selectedFilter} handleChange={handleChange} />
            <RadioButton default value="Unassigned" selectedValue={selectedFilter} handleChange={handleChange} />
          </div>
          <Dropdown label="Sort by: " options={mainAlertOptions} updateOption={updateSortOption} />

        </div>
      </div>
      <div className="scrollable-wrapper">
        <table>
          <thead>
            <tr>
              <th>Kit Number</th>
              <th>Address</th>
              <th>Date Assigned</th>
              <th>Date Unassigned</th>
              <th>{}</th>
            </tr>
          </thead>
          <tbody>
            {displayedKits.length > 0
              ? displayedKits.map((kit) => (
                <tr key={kit?.name} className={kit?.address ? 'white' : 'grey'} onClick={() => setSelectedKit(kit)}>
                  <td>{kit?.name}</td>
                  <td>{kit?.address}</td>
                  <td>{kit?.lastAssignedAt ? formatDate(kit?.lastAssignedAt, timeZone) : null}</td>
                  <td>
                    {kit?.lastUnassignedAt ? formatDate(kit?.lastUnassignedAt, timeZone) : null}
                  </td>
                  <td><img src={deleteImg} alt="delete" className="user-icons" onClick={() => deleteSelectedKit()} /></td>
                </tr>
              ))
              : (
                <tr style={{ border: 'transparent' }}>
                  <td>No Kits Available</td>
                </tr>
              ) }

          </tbody>
        </table>
      </div>
      {showPopup && <Popup closePopup={() => setShowPopup(false)} title="Delete Kit" child={<DeleteKitComponent kit={selectedKit} close={setShowPopup} />} />}
    </div>
  );
}

export default KitsTab;
