/* eslint-disable */
import {
  getAutomations, getDeviceGroups, getDevices,
  getEvents, getRooms, getSites, getSite, patchEvent,
  getInvitations, addInvitee, updateInvitee, deleteInvitee,
  updateNotification,
  patchRoomSettings,
  deleteDeviceGroup,
  toggleSensor,
  setThreatLevel,
} from '../api/UserAPI';
import {
  updateActiveList, updateArchivedList,
  updateAutomationList,
  updateDeviceList,
  updateEventList,
  updateRoomList,
  updateSelectedRoomId,
  updateSelectedRoomName,
  updateSitesList,
  updateUserRole,
  updateUnassignedKitsState,
  updateAssingedKitsState,
  updateOwnerId,
  updateUsers,
} from '../redux/userInfo';

const emptyList = [];

// update SITES (CITIES)
export const updateSites = async (dispatch) => {
  const response = await getSites();
  if (response?.success) {
    dispatch(updateSitesList(response?.sites));
  }
};

// get site information
export const getSiteInfo = async (dispatch, siteId) => {
  const response = await getSite(siteId);
  if (response.success) {
    dispatch(updateUserRole(response?.user.role));
  }
};

export const getSiteOwnerId = async (dispatch, siteId) => {
  const response = await getSite(siteId);
  dispatch(updateOwnerId(response.siteMetadata.owner));
};

// update ROOMS (PROPERTIES)
export const updateRooms = async (dispatch, siteId) => {
  const response = await getRooms(siteId);
  console.log('dispatch rooms');
  if (response.rooms && response.rooms.length) {
    dispatch(updateRoomList(response));

    const emptyRooms = response.rooms?.filter((room) => room.deviceCount === 0);
    if (emptyRooms.length) {
      const emptyRoomsIdList = emptyRooms?.map(({ roomId }) => (roomId));
      dispatch(updateArchivedList(emptyRoomsIdList));
    } else {
      dispatch(updateArchivedList(emptyList));
    }
  } else {
    dispatch(updateRoomList(emptyList));
    dispatch(updateSelectedRoomName(''));
    dispatch(updateSelectedRoomId(''));
  }
};

// update DEVICES
export const updateDevices = async (dispatch, siteId) => {
  const response = await getDevices(siteId);
  console.log('dispatch devices');
  if (response.devices && response.devices.length) {
    dispatch(updateDeviceList(response));
  } else {
    dispatch(updateDeviceList(emptyList));
  }
};

// update AUTOMATIONS
export const updateAutomations = async (dispatch, siteId) => {
  const response = await getAutomations(siteId);
  console.log('dispatch automations');
  if (response?.automations?.length) {
    dispatch(updateAutomationList(response));
  } else {
    dispatch(updateAutomationList(emptyList));
  }
};

// update EVENTS
export const updateEvents = async (dispatch, selectedSiteId, roomId, limit, startTimestamp, endTimestamp, source, orderBy) => {
  const response = await getEvents({ siteId: selectedSiteId, roomId, limit, lastTimestamp: '', startTimestamp, endTimestamp, source, orderBy,  type: ['event', 'alert'] });
  if (response.events && response.events.length) {
    // create new list with events only from selectedSiteId
    const newList = response.events.filter((event) => event?.content?.siteId === selectedSiteId);
    // update list of events where status = resolved
    dispatch(updateEventList(newList));
  } else {
    dispatch(updateEventList(emptyList));
  }
};

export const updateAlerts = async (dispatch, selectedSiteId, roomId, orderBy = 'desc', limit) => {
  const alertResponse = await getEvents({
    siteId: selectedSiteId, roomId, type: 'alert', orderBy, limit, status: ['NEW', 'UNRESOLVED'],
  });
  if (alertResponse.events && alertResponse.events.length) {
    dispatch(updateActiveList(alertResponse.events));
  } else {
    dispatch(updateActiveList(emptyList));
  }
};

export const updateAlertsIncrementally = async (dispatch, selectedSiteId, roomId, limit, lastTimestamp, existingEventList, orderBy = 'desc') => {
  const response = await getEvents({
    siteId: selectedSiteId, roomId, type: 'alert', lastTimestamp, limit, orderBy, status: ['NEW', 'UNRESOLVED'],
  });
  if (response.events && response.events.length) {
    dispatch(updateActiveList([...existingEventList, ...response.events]));
  }
  return response.events;
};

export const getAlertsCount = async (selectedSiteId, roomId) => {
  const response = await getEvents({ siteId: selectedSiteId, roomId, type: 'alert' });
  if (response.events && response.events.length) {
    // update list of events where status = resolved
    const activeList = response.events.filter((event) => (event?.status === 'NEW' || event?.status === 'UNRESOLVED'));
    return activeList.length;
  }
  return 0;
};

// update EVENTS
export const updateEventsIncrementally = async (dispatch, siteId, roomId, limit, lastTimestamp, existingEventList, startTimestamp, endTimestamp, source, orderBy = 'desc') => {
  const response = await getEvents({
    siteId, roomId, limit, lastTimestamp, startTimestamp, endTimestamp, source, orderBy, type: ['event', 'alert'],
  });
  if (response.events && response.events.length) {
    // update list of events where status = resolved
    dispatch(updateEventList([...existingEventList, ...response.events]));
  }
  return response.events;
};

// update UNASSIGNED KITS
export const updateUnassignedKits = async (dispatch, siteId) => {
  const response = await getDeviceGroups(siteId, false);
  console.log('dispatch unassigned kits');
  if (response?.deviceGroups) {
    dispatch(updateUnassignedKitsState(response?.deviceGroups));
  } else {
    dispatch(updateUnassignedKitsState(emptyList));
  }
};

// update assigned kits
export const updateAssignedKits = async (dispatch, siteId) => {
  const response = await getDeviceGroups(siteId, true);
  console.log('dispatch assigned kits');
  if (response?.deviceGroups) {
    dispatch(updateAssingedKitsState(response?.deviceGroups));
  } else {
    dispatch(updateAssingedKitsState(emptyList));
  }
};

export const updateUsersInfo = async (dispatch, siteId) => {
  const response = await getInvitations(siteId);
  if (response.owner) {
    dispatch(updateUsers(response.invitations));
  }
};

export const addUser = async (dispatch, siteId, email, accessLevel) => {
  const response = await addInvitee(siteId, email, accessLevel);
  if (response) {
    const currentUsers = await getInvitations(siteId);
    dispatch(updateUsers(currentUsers.invitations));
  }
};

export const editUser = async (dispatch, siteId, userId, accessLevel) => {
  const response = await updateInvitee(siteId, userId, accessLevel);
  if (response) {
    const currentUsers = await getInvitations(siteId);
    dispatch(updateUsers(currentUsers.invitations));
  }
};

export const deleteUser = async (dispatch, siteId, userId) => {
  const response = await deleteInvitee(siteId, userId);
  if (response) {
    const currentUsers = await getInvitations(siteId);
    dispatch(updateUsers(currentUsers.invitations));
  } else {
    console.log('user can not be deleted');
  }
};

// to do: error handling & add 'are you sure' popup
// when alert is archived - set status to RESOLVED
export const archiveAlert = async (dispatch, eventId, selectedSiteId, roomId, alertsOrder) => {
  const response = await patchEvent(selectedSiteId, eventId, 'RESOLVED');
  if (response?.status === 'RESOLVED') {
    if (alertsOrder) {
      await updateAlertsIncrementally(dispatch, selectedSiteId, roomId, 25, [], [], alertsOrder);
    } else {
      await updateAlerts(dispatch, selectedSiteId, roomId);
    }
  } else {
    console.log('event could not be archived');
  }
};

export const archiveDirectly = (dispatch, event, eventId, siteId, roomId, alertsOrder) => {
  archiveAlert(dispatch, eventId, siteId, roomId, alertsOrder);
  event.preventDefault();
  event.stopPropagation();
  event.nativeEvent.stopImmediatePropagation();
};

export const archiveSelectedEvents = (dispatch, event, events, siteId, roomId, alertsOrder) => {
  events.map((event) => {
    archiveAlert(dispatch, event.eventId, siteId, roomId, alertsOrder);
  });
  event.preventDefault();
  event.stopPropagation();
  event.nativeEvent.stopImmediatePropagation();
};

// add sensor count to rooms
export const assignSensorCount = (devices, rooms) => {
  const updatedArray = rooms.map((item) => ({ ...item, offlineSensorsCount: 0 }));
  for (const device of devices) {
    let index = -1;
    if (device.isConnected === false) index = updatedArray.findIndex((room) => room.roomId === device.roomId);
    if (index !== -1) {
      const counter = updatedArray[index].offlineSensorsCount + 1;
      updatedArray[index] = { ...updatedArray[index], offlineSensorsCount: counter };
    }
  }
  return updatedArray;
};

// seperate archived properties from unarchived ones
export const seperateArchived = (rooms, archivedList) => {
  const archived = [];
  const notArchived = [];
  rooms.map((room) => (archivedList.includes(room.roomId)
    ? archived.push(room)
    : notArchived.push(room)));
  return notArchived.concat(...archived);
};

export const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const customSortFunction = (firstElement, secondElement, orderBy) => {
  // equal items sort equally
  if (firstElement === secondElement) return 0;
  if (firstElement === null) return 1;
  if (secondElement === null) return -1;
  if (orderBy === 'des') return firstElement > secondElement ? -1 : 1;
  return firstElement > secondElement ? 1 : -1;
};

export const updateTheme = (audience) => {
  const getPrimaryColor = (audience) => {
    if (audience === "first_key") {
      return '#662FA0';
    } else if (audience === "progress_residential") {
      return '#08807F';
    } else if (audience === "stonecrest") {
        return '#677781'
    } else if (audience === "rogers_business") {
      return '#da291c'
    } else {
      return '#262626';
    }
  }

  const getFilter = (audience) => {
    if (audience === "first_key") {
      return 'invert(19%) sepia(97%) saturate(1611%) hue-rotate(254deg) brightness(89%) contrast(95%)';
    } else if (audience === "progress_residential") {
      return 'invert(56%) sepia(60%) saturate(2754%) hue-rotate(342deg) brightness(100%) contrast(87%)';
    } else if (audience === "stonecrest") {
      return  'invert(27%) sepia(93%) saturate(1488%) hue-rotate(176deg) brightness(101%) contrast(85%)';
    } else if (audience === "rogers_business") {
      return 'invert(66%) sepia(9%) saturate(224%) hue-rotate(165deg) brightness(87%) contrast(86%)'
    } else { 
      return 'invert(11%) sepia(71%) saturate(6207%) hue-rotate(328deg) brightness(102%) contrast(101%)';
    }
  }

  const getAccentColor = (audience) => {
    if (audience === "first_key") {
      return '#52C4E9';
    } else if (audience === "progress_residential") {
      return '#ED7534';
    } else if (audience === 'stonecrest') {
      return  '#137CAF';
    } else if (audience === "rogers_business") { 
      return '#8d9398'
    }else {
      return '#D8095F';
    }
  }

  document.documentElement.style.setProperty('--progress-orange', getAccentColor(audience));
  document.documentElement.style.setProperty('--progress-green', getPrimaryColor(audience));
  document.documentElement.style.setProperty('--filter', getFilter(audience));
}

export const validateTime = (start, end) => {
  if (end <= start) return false;
  return true;
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const updateNotificationStatus = async (siteId, enableEmailStatus, enableSmsStatus, enablePushStatus) => {
  const response = await updateNotification(siteId, enableEmailStatus, enableSmsStatus, enablePushStatus); 
  return response;
}

export const updateRoomSettings = async (dispatch, siteId, roomId, alertsMode) => {
  const response = await patchRoomSettings (siteId, roomId, alertsMode);
  if (response) {
    const response = await getRooms(siteId);
    if (response.rooms && response.rooms.length) {
      dispatch(updateRoomList(response));
    }
  }
  return response;
}

export const deleteKit = async (dispatch, siteId, deviceGroupId) => {
  const response = await deleteDeviceGroup(siteId, deviceGroupId);
  return response;
}

export const updateSensor = async (dispatch, siteId, deviceId, value) => {
  const response = await toggleSensor(siteId, deviceId, value);
  if (response.success === true)
    updateDevices(dispatch, siteId);
}
