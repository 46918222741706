/* eslint-disable */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PubSub } from 'aws-amplify';
import {
  getDeviceState, requestDatastream, subscribeToDeviceChanges, subscribeToHostChanges
} from '../api/UserAPI';
import warning from '../assets/warning.svg';
import TileComponent from './TileComponent';
import { updateDevicesOrder, updateVideoInfo } from '../redux/userInfo';
import { updateDevices } from './Update';

function DisplayDeviceContent(props) {
  const installationStatus = props?.device?.tags ? props?.device?.tags.find(tag => tag.key === 'installationStatus') : '';
  const dispatch = useDispatch();
  // site info
  const siteOwnerId = useSelector((state) => state.userInfo.ownerId);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const videoInfo = useSelector((state) => state.userInfo.videoInfo);
  const devicesOrder = useSelector((state) => state.userInfo.devicesOrder);
  const [type] = useState(props?.device?.insert?.functions ?? []);
  const [insertType] = useState(props?.device?.insert?.type ?? null);
  const [name] = useState(props?.device?.host?.components[0]?.name);
  const [isConnected, setIsConnected] = useState(props?.device?.isConnected); // true = online, false = offline
  const [occupied, setOccupied] = useState('');
  const [leak, setLeak] = useState(false);
  const [detected, setDetected] = useState(true);
  const [onlyOccupied] = useState(props?.onlyOccupied ?? false);
  const [deviceSubscription, setDeviceSubscription] = useState(null);
  const [update, setUpdate] = useState(true); // state to ensure useeffect runs and the setInterval runs
  const subscription = useRef();
  const datastreamInterval = useRef();

  useEffect(() => {
    setIsConnected(props?.device?.isConnected);
  },[props?.device?.isConnected])

  const deviceUpdater = async() => {
    await updateDevices(dispatch, selectedSiteId);
  }

  useEffect(() => {
    if (siteOwnerId  && selectedSiteId) {
      if (deviceSubscription) {
        deviceSubscription.unsubscribe();
      }
      const subscription = subscribeToHostChanges(
        siteOwnerId ,
        selectedSiteId,
        props?.device?.deviceId,
        { PubSub },
        (data) => {
          console.log('DATA INSIDE Host SUBSCRIPTION', data);
          deviceUpdater();
        },
      );
      setDeviceSubscription(subscription);
    }
    return () => {
      if (deviceSubscription) {
        deviceSubscription.unsubscribe();
      }
    };
  }, [siteOwnerId, selectedSiteId, props?.device?.deviceId]);

  useEffect(() => {
    const init = async () => {
      let data;
      if (isConnected) {
        const response = await getDeviceState(siteOwnerId, selectedSiteId, props?.device?.deviceId, isConnected);
        if (response === null) deviceUpdater(); // The device can be offline so update the devices info
      subscription.current = subscribeToDeviceChanges(
        siteOwnerId,
        selectedSiteId,
        props?.device?.deviceId,
        (state) => {
          data = state.data;
          if (!data) {
            deviceUpdater();
            return;
          }
        },
        isConnected
      );
  
      await requestDatastream(siteOwnerId, selectedSiteId, props?.device?.deviceId, isConnected);
      const intervalId = setInterval(async () => {
        if(datastreamInterval.current) {
          clearInterval(datastreamInterval.current);
        }
      datastreamInterval.current = intervalId;
      await requestDatastream(siteOwnerId, selectedSiteId, props?.device?.deviceId, isConnected);
      setUpdate(!update);
    }, 2 * 60 * 1000); // 2 minutes interval

      if (type.includes('motion')) {
        let result = '';
        const state = response?.value?.data?.insert;
        result = state?.motion?.occupied;
        if (result === true) {
          setOccupied('occupied');
        } else if (result === false || result === undefined) {
          setOccupied('vacant');
        }
        if (data) {
          const insert = data?.insert;
          if (!insert) return;
          if (insert?.motion?.occupied !== undefined) {
            setOccupied(insert.motion.occupied ? 'occupied' : 'vacant');
            if(insertType === 'video') {
              const deviceId = props?.device?.deviceId;
              const newObj = {...videoInfo};
              newObj[deviceId] =  { viewers: insert?.video?.webrtc?.currentViewers, rssi: data?.connection?.rssi,  sd: insert?.video?.sd?.mem} 
              dispatch(updateVideoInfo(newObj));
            }
          }
        }
      } else if (type.includes('water')) {
        setLeak(response?.value?.data?.insert?.water?.water?.state);
        const insert = data?.insert;
            if (!insert) return;
            if (insert?.water?.water?.state !== undefined) {
              setLeak(insert.water.water.state);
            }
      } else if (type.includes('distance')) {
        if (response) setDetected(response?.value?.data?.insert?.distance?.proximity?.detected && response?.value?.data?.insert?.distance?.proximity.distance / 10 < 30); //distance in mm. Threshold 30cm
        if (data) {
          const insert = data?.insert;
          if (!insert) return;
          if (insert?.distance?.proximity?.detected !== undefined) {
          setDetected(insert?.distance?.proximity?.detected && insert?.distance?.proximity.distance / 10 < 30);
          }
        }
      }
    }
    };
    init();
    return () => {
      if(subscription.current) {
        subscription.current.unsubscribe();
        subscription.current = null;
      }
      if(datastreamInterval.current) {
        clearInterval(datastreamInterval.current);
      }
    };
  }, [props?.device?.deviceId, selectedSiteId, siteOwnerId, type, update]);


  useEffect(() => {
    if (type.includes('distance')) {
      const newObj = {...devicesOrder};
      if (!detected ) newObj[props?.device?.deviceId] = 1;
      else newObj[props?.device?.deviceId] = 0;
      dispatch(updateDevicesOrder(newObj));
    }
  },[detected])

  useEffect(() => {
    if (type.includes('water')) {
      const newObj = {...devicesOrder};
      if (leak) newObj[props?.device?.deviceId] = 1;
      else newObj[props?.device?.deviceId] = 0;
      dispatch(updateDevicesOrder(newObj));
      }
  },[leak])

  const getComponent = () => {
    if (!onlyOccupied) {
      return (
        <>
          {type.includes('video') && isConnected
            ? <TileComponent installationStatus={installationStatus} className={`motion ${occupied === 'occupied' ? 'highlight occupied' : ''}`} name={name} icon="video" status="Online" deviceId={props?.device?.deviceId} /> 
            : type.includes('video') && !isConnected
              ? <TileComponent installationStatus={installationStatus} className="offline highlight" name={name} icon="video" status="Offline" img={true} ImgSource={warning} imgAlt="offline" imgClassName="warning" deviceId={props?.device?.deviceId} />
              : type.includes('motion') && isConnected
                ? <TileComponent installationStatus={installationStatus} className={`motion ${occupied === 'occupied' ? 'highlight occupied' : ''}`} name={name} icon="motion" status={occupied} deviceId={props?.device?.deviceId} /> 
                : type.includes('motion') && !isConnected
                  ? <TileComponent installationStatus={installationStatus} className="offline highlight" name={name} icon="motion" status="Offline" img={true} ImgSource={warning} imgAlt="offline" imgClassName="warning" deviceId={props?.device?.deviceId} />
                  : type.includes('power_out') && isConnected
                    ? <TileComponent installationStatus={installationStatus} name={name} icon="powerOut" status="Online" deviceId={props?.device?.deviceId} />
                    : type.includes('power_out') && !isConnected
                      ? <TileComponent installationStatus={installationStatus} className= "offline highlight" name={name} icon="powerOut" status="Offline" img={true} ImgSource={warning} imgAlt="offline" imgClassName="warning" deviceId={props?.device?.deviceId}  /> 
                      : type.includes('aq') && isConnected
                        ? <TileComponent installationStatus={installationStatus} name={name} icon="aq" status="Online" deviceId={props?.device?.deviceId} /> 
                        : type.includes('aq') && !isConnected
                          ? <TileComponent installationStatus={installationStatus} className= "offline highlight" name={name} icon="aq" status="Offline" img={true} ImgSource={warning} imgAlt="offline" imgClassName="warning" deviceId={props?.device?.deviceId}/>
                          : type.includes('guidelight') && isConnected
                            ? <TileComponent installationStatus={installationStatus} name={name} icon="guideLight" status="Online" deviceId={props?.device?.deviceId} />
                            : type.includes('guidelight') && !isConnected
                              ? <TileComponent installationStatus={installationStatus} className= "offline highlight" name={name} icon="guideLight" status="Offline" img={true} ImgSource={warning} imgAlt="offline" imgClassName="warning" deviceId={props?.device?.deviceId} />
                              : type.includes('usb') && isConnected
                                ? <TileComponent installationStatus={installationStatus} name={name} icon="usb" status="Online" deviceId={props?.device?.deviceId} /> 
                                : type.includes('usb') && !isConnected
                                  ? <TileComponent installationStatus={installationStatus} className= "offline highlight" name={name} icon="usb" status="Offline" img={true} ImgSource={warning} imgAlt="offline" imgClassName="warning" deviceId={props?.device?.deviceId} />
                                  : type.includes('pm') && isConnected
                                    ? <TileComponent installationStatus={installationStatus} name={name} icon="pm" status="Online" deviceId={props?.device?.deviceId} />
                                    : type.includes('pm') && !isConnected
                                      ? <TileComponent installationStatus={installationStatus} className= "offline highlight" name={name} icon="pm" status="Offline" img={true} ImgSource={warning} imgAlt="offline" imgClassName="warning" deviceId={props?.device?.deviceId}/>
                                      : type.includes('water') && isConnected && !leak
                                        ? <TileComponent installationStatus={installationStatus} name={name} icon="water" status="Online" deviceId={props?.device?.deviceId} />
                                        : type.includes('water') && !isConnected
                                          ? <TileComponent installationStatus={installationStatus} className= "offline highlight" name={name} icon="water" status="Offline" img={true} ImgSource={warning} imgAlt="offline" imgClassName="warning" deviceId={props?.device?.deviceId} />
                                          : type.includes('water') && leak
                                          ? <TileComponent installationStatus={installationStatus} className="alert highlight" name={name} icon="water" status="Online" img={true} ImgSource={warning} imgAlt="leak" imgClassName="warning" deviceId={props?.device?.deviceId}/> 
                                          : type.includes('co2') && isConnected
                                            ? <TileComponent installationStatus={installationStatus} name={name} icon="co2" status="Online"  deviceId={props?.device?.deviceId} />
                                            : type.includes('co2') && !isConnected
                                              ? <TileComponent installationStatus={installationStatus} className= "offline highlight" name={name} icon="co2" status="Offline" img={true} ImgSource={warning} imgAlt="offline" imgClassName="warning" deviceId={props?.device?.deviceId} />
                                              : type.includes('distance') && isConnected && detected
                                                ? <TileComponent installationStatus={installationStatus} name={name} icon="proximity" status="Detected"  deviceId={props?.device?.deviceId} />
                                                : type.includes('distance') && !isConnected
                                                  ? <TileComponent installationStatus={installationStatus} name={name} className="offline highlight" icon="proximity" status="Offline" img={true} ImgSource={warning} imgAlt="Offline" imgClassName="warning"  deviceId={props?.device?.deviceId} />
                                                  : type.includes('distance') && !detected
                                                    ? <TileComponent installationStatus={installationStatus} className="alert highlight" name={name} icon="proximity" status="Not Detected" deviceId={props?.device?.deviceId}/>
                                                    : null}
        </>
      );
    } if (onlyOccupied && occupied === 'occupied') {
      return (
        <TileComponent installationStatus={installationStatus} className={`tile motion ${occupied === 'occupied' ? 'highlight occupied' : ''}`} name={name} icon="motion" status={occupied} deviceId={props?.device?.deviceId} /> 
      );
    }

    return <div />;
  };
  return getComponent();
}

export default DisplayDeviceContent;
