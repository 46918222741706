import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop } from '@mui/material';
import ViewEventPopup from './ViewEventPopup';
import AlertsTab from './AlertsTab';
import SettingsTab from './SettingsTab';
import PropertiesTab from './PropertiesTab';
import UsersTab from './UsersTab';
import KitsTab from './KitsTab';
import AccountDetails from './AccountDetails';
import { updateCurrentTab } from '../redux/userInfo';
import SupportTab from './SupportTab';

function DashboardTab(props) {
  const { logout } = props;

  // site info
  const viewEventPopup = useSelector((state) => state.userInfo.viewEventPopup);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const selectedRoomId = useSelector((state) => state.userInfo.selectedRoomId);
  const currentTab = useSelector((state) => state.userInfo.currentTab);

  const [selectedEvent, setSelectedEvent] = useState({});
  const [isAlertLoading, setAlertLoading] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState(currentTab !== '' ? currentTab : 'Alerts');

  const dispatch = useDispatch();

  const updateTab = (selectedTab) => {
    setSelectedNavItem(selectedTab);
    dispatch(updateCurrentTab(selectedTab));
  };

  return (
    <div id="dashboard" className="right-container">

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isAlertLoading}
      />
      {viewEventPopup
        ? (
          <ViewEventPopup
            selectedSiteId={selectedSiteId}
            roomId={selectedRoomId}
            selectedEvent={selectedEvent}
            insideLog={false}
          />
        )
        : null}

      <header>
        <button className="primary-btn" onClick={logout}>Logout</button>
      </header>

      <div className="settings-wrapper">
        <h4 className={selectedNavItem === 'Alerts' ? 'selected' : ''} onClick={() => updateTab('Alerts')}>Alerts</h4>
        <h4 className={selectedNavItem === 'Settings' ? 'selected' : ''} onClick={() => updateTab('Settings')}>Settings</h4>
        <h4 className={selectedNavItem === 'Users' ? 'selected' : ''} onClick={() => updateTab('Users')}>Users</h4>
        <h4 className={selectedNavItem === 'Properties' ? 'selected' : ''} onClick={() => updateTab('Properties')}>Properties</h4>
        <h4 className={selectedNavItem === 'Kits' ? 'selected' : ''} onClick={() => updateTab('Kits')}>Kits</h4>
        <h4 className={selectedNavItem === 'Account-Details' ? 'selected' : ''} onClick={() => updateTab('Account-Details')}>Account Details</h4>
        <h4 className={selectedNavItem === 'Support' ? 'selected' : ''} onClick={() => updateTab('Support')}>Support</h4>

      </div>
      { selectedNavItem === 'Alerts' && <AlertsTab setSelectedEvent={setSelectedEvent} setAlertLoading={setAlertLoading} /> }
      { selectedNavItem === 'Settings' && <SettingsTab />}
      { selectedNavItem === 'Users' && <UsersTab />}
      { selectedNavItem === 'Properties' && <PropertiesTab />}
      { selectedNavItem === 'Kits' && <KitsTab />}
      { selectedNavItem === 'Account-Details' && <AccountDetails />}
      { selectedNavItem === 'Support' && <SupportTab />}
    </div>
  );
}
export default DashboardTab;
