/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from './Dropdown';
import { addUser, editUser, validateEmail } from './Update';

function AddUserComponent(props) {
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const [userData, setUserData] = useState({ email: props.user ? props.user.invitee.email : '', accessLevel: props.user ? props.user.role : '' });
  const [emailerror, setEmailError] = useState(false);
  const [accessLevelEmpty, setAccessLevelEmpty] = useState(false);
  const [indexOfOption] = useState(props.user ? (props.user.role === 'admin' ? 0 : (props.user.role === 'guest' ? 1 : null)) : null);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setUserData({ ...userData, email: event.target.value });
  };

  const updateAccessLevel = (index) => {
    if (index === 0) setUserData({ ...userData, accessLevel: 'admin' });
    else setUserData({ ...userData, accessLevel: 'guest' });
  };

  const saveUserData = async () => {
    if (props.edit) {
      await editUser(dispatch, selectedSiteId, props.user.invitationId, userData.accessLevel);
      props.close(false);
    } else {
      if (validateEmail(userData.email)) {
        await addUser(dispatch, selectedSiteId, userData.email, userData.accessLevel);
        setEmailError(false);
        props.close(false);
      } else {
        setEmailError(true);
      }
      userData.accessLevel !== '' ? setAccessLevelEmpty(false) : setAccessLevelEmpty(true);
    }
  };

  return (
    <>
      <div id="add-user-container">
        <input type="text" placeholder="Email" autoComplete="off" onChange={(event) => handleChange(event)} value={userData.email} readOnly={props.edit} />
        {emailerror && <span className="error add-user-error">Wrong Email</span>}
        <Dropdown label="Access Level:" options={['Admin', 'Guest']} className="users-levels-options" blackArrow hideFirstOption={!props.edit} firstSelectedOptionIndex={indexOfOption ?? null} updateOption={updateAccessLevel} />
      </div>
      {accessLevelEmpty && <span className="error add-user-error">Please set an access level</span>}
      <button className="primary-btn" onClick={() => saveUserData()}>save</button>
    </>
  );
}

export default AddUserComponent;
