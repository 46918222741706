import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import EventsTable from './EventsTable';

function ActiveAlertsContainer(props) {
  const selectedRoomId = useSelector((state) => state.userInfo.selectedRoomId);
  const activeList = useSelector((state) => state.userInfo.activeList);
  const [activeAlerts, setActiveAlerts] = useState([]);

  useEffect(() => {
    // list of events where status = new / unresolved (IN SELECTED ROOM)
    let alerts = [];
    if (activeList.length) {
      alerts = activeList?.filter(
        (event) => event?.content?.roomId === selectedRoomId,
      );
    }
    setActiveAlerts(alerts ?? []);
  }, [activeList, selectedRoomId]);

  return (
    <>
      { !props.hideTitle ? <h2 className="mb-05">Active Alerts</h2> : null}
      <EventsTable
        list={activeAlerts}
        setAlertLoading={props.setAlertLoading}
        setSelectedEvent={props.setSelectedEvent}
      />
    </>
  );
}
export default ActiveAlertsContainer;
