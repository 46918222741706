export default function randomString(length) {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyz';
  let result = '';
  for (let i = 0; i < length; i += 1) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
}

// download CSV file
export const downloadCSV = (data) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', 'activity-log.csv');
  a.click();
};

export function isValidUserId(userId) {
  if (!userId || userId.trim() === '') {
    console.error('Invalid userId');
    return false;
  }
  return true;
}
