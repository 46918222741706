import React, { useEffect, useState } from 'react';
import EventsTagsFilter from './EventsTagsFilter';

function AlertsFilter(props) {
  const [emptyFilter, setEmptyFilter] = useState(true);

  const clearFilter = () => {
    props.setShowPopup(false);
    props.updateTag('All');
  };

  const applyFilter = () => props.setShowPopup(false);

  useEffect(() => {
    if (props?.tag !== 'All') {
      setEmptyFilter(false);
    }
  }, [props.tag]);
  return (
    <>
      <EventsTagsFilter tag={props.tag} updateTag={props.updateTag} />
      <div className="filter-buttons">
        <button className="primary-btn clear-filter" onClick={applyFilter} disabled={emptyFilter}>Ok</button>
        <button className="primary-btn clear-filter" onClick={clearFilter} disabled={emptyFilter}>Clear Filter</button>
      </div>
    </>
  );
}

export default AlertsFilter;
