import React from 'react';
import './Popup.css';
import close from '../assets/close.svg';

function Popup(props) {
  return (
    <div id="general-popup" className="popup">
      <div className="popup-content">
        <h2 className="black mb-2">{props.title}</h2>
        <img src={close} alt="close" className="close-btn" onClick={props.closePopup} />
        {props.child}
      </div>

    </div>
  );
}

export default Popup;
