import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteKit, updateAssignedKits, updateUnassignedKits } from './Update';

function DeleteKitComponent(props) {
  const dispatch = useDispatch();
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);

  const deleteSelectedKit = async () => {
    const response = await deleteKit(dispatch, selectedSiteId, props.kit?.deviceGroupId);
    if (response) {
      await (updateAssignedKits(dispatch, selectedSiteId));
      await (updateUnassignedKits(dispatch, selectedSiteId));
    }
    props.close(false);
  };
  return (
    <div>
      <p>{`Are you sure you want to delete this kit (${props.kit?.name})`}</p>
      <div className="buttons-group">
        <button className="primary-btn mr-05" onClick={() => props.close(false)}>NO</button>
        <button className="primary-btn" onClick={() => deleteSelectedKit()}>Yes</button>
      </div>
    </div>
  );
}
export default DeleteKitComponent;
