/* eslint-disable no-unsafe-optional-chaining */

// format event source
export function formatSource(source) {
  let newSource = '';
  switch (source) {
    case 'powerToggled':
      newSource = 'Power toggled';
      break;
    case 'buttonPresses':
      newSource = 'Button presses';
      break;
    case 'airQuality':
      newSource = 'Air quality';
      break;
    case 'waterSensor':
      newSource = 'Water sensor';
      break;
    case 'connectionStatus':
      newSource = 'Connection status';
      break;
    case 'deviceRestarted':
      newSource = 'Device restarted';
      break;
    case 'schedule':
    case 'access':
    case 'video':
    case 'snapshot':
    case 'energy':
    case 'occupancy':
    case 'temp':
    case 'humi':
    case 'pressure':
    case 'light':
    case 'vibration':
    case 'water':
    default:
      newSource = source?.charAt(0).toUpperCase() + source?.slice(1);
  }
  return newSource;
}

// format timestamp to readable date
export function formatDate(timeStamp, timeZone) {
  if (typeof timeStamp !== 'number') {
    return 'Error';
  }
  const date = new Date(timeStamp).toLocaleDateString('en-US', { timeZone });
  return date;
}

// format timestamp to readable time
export function formatTime(timeStamp, timeZone) {
  if (typeof timeStamp !== 'number') {
    return 'Error';
  }
  const time = new Date(timeStamp).toLocaleTimeString('en-US', { timeZone, hourCycle: 'h23' });
  return time;
}
