import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser } from './Update';

function DeleteUserComponent(props) {
  const dispatch = useDispatch();
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);

  const deleteSelectedUser = async () => {
    await deleteUser(dispatch, selectedSiteId, props.user.invitationId);
    console.log('user deleted');
    props.close(false);
  };
  return (
    <div>
      <p>Are you sure you want to delete this user?</p>
      <div className="buttons-group">
        <button className="primary-btn mr-05" onClick={() => props.close(false)}>NO</button>
        <button className="primary-btn" onClick={() => deleteSelectedUser()}>Yes</button>
      </div>
    </div>
  );
}
export default DeleteUserComponent;
