import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DisplayDeviceContent from './DisplayDeviceContent';
import LiveVideoStream from './LiveVideoStream';
import './Popup.css';
import ActiveAlertsContainer from './ActiveAlertsContainer';
import AlertsToggle from './AlertsToggle';
import { customSortFunction } from './Update';

function AddressTab(props) {
  const deviceList = useSelector((state) => state.userInfo.deviceList);
  const selectedRoomId = useSelector((state) => state.userInfo.selectedRoomId);
  const devicesOrder = useSelector((state) => state.userInfo.devicesOrder);
  const [cameraList, setCameraList] = useState([]);

  useEffect(() => {
    // list of cameras
    const cameras = deviceList?.devices?.filter(
      (device) => device?.roomId === selectedRoomId && device?.insert?.type.toLowerCase() === 'video',
    );
    setCameraList(cameras ?? []);
  }, [deviceList, selectedRoomId]);

  // display online inserts (except cameras) in devices section
  const displayAllSensors = (deviceList) => {
    const allSensors = deviceList?.devices?.filter(
      (device) => device?.roomId === selectedRoomId && device?.insert?.type.toLowerCase() !== 'video',
    );

    if (allSensors && allSensors.length) allSensors.sort((firstElement, secondElement) => customSortFunction(devicesOrder[firstElement.deviceId], devicesOrder[secondElement.deviceId], 'asc'));

    return (
      allSensors?.length
        ? allSensors?.map((device) => (
          <DisplayDeviceContent key={device.deviceId} device={device} />
        ))
        : null
    );
  };

  const getCameraComponent = (cameraList) => (
    cameraList?.length
      ? (
        <div className="video-wrapper">
          {cameraList?.map((camera) => (
            <div key={camera.thingName}>
              <LiveVideoStream
                key={camera.thingName}
                thingName={camera.thingName}
                device={camera}
              />
            </div>
          ))}
        </div>
      )
      : null
  );

  return (
    <div>
      <div className="content">
        <AlertsToggle />
        <h2 className="mb-05">Devices</h2>

        <div className="white-wrapper">
          {getCameraComponent(cameraList)}
          <div className="sensor-wrapper">
            {displayAllSensors(deviceList)}
          </div>
        </div>

        <ActiveAlertsContainer
          setAlertLoading={props.setAlertLoading}
          setSelectedEvent={props.setSelectedEvent}
        />

      </div>
    </div>
  );
}

export default AddressTab;
