/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePhoneState } from '../redux/userInfo';
import { deletePhoneNumber, updatePhoneNumber, verifyPhoneNumber } from '../api/UserAPI';

function AddPhoneNumber() {
  const dispatch = useDispatch();
  const phoneVerified = useSelector((state) => state.userInfo.phoneVerified);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [savedPhoneNumber, setSavedPhoneNumber] = useState(localStorage.getItem('phoneNumber'));
  const [showVerification, setShowVerification] = useState(!phoneVerified && savedPhoneNumber !== 'null');
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerifcationError, setShowVerificationError] = useState(false);
  const [showAddNumberError, setShowAddNumberError] = useState(false);
  const [hiddenNumber, setHiddenNumber] = useState('');

  useEffect(() => {
    setHiddenNumber(savedPhoneNumber !== 'null' ? `${savedPhoneNumber.slice(0, 2)} ${savedPhoneNumber.slice(2, -2).replace(/[0-9]/g, '*')} ${savedPhoneNumber.slice(-2)}` : '');
  }, [savedPhoneNumber]);

  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const addNumber = async () => {
    const response = await updatePhoneNumber(phoneNumber);
    if (response.phoneNumber) {
      setPhoneNumber('');
      setShowAddNumberError(false);
      localStorage.setItem('phoneNumber', response.phoneNumber);
      setSavedPhoneNumber(localStorage.getItem('phoneNumber'));
      setShowVerification(true);
    } else {
      setShowAddNumberError(true);
    }
  };

  const handleChangeVerifcationCode = (event) => {
    setVerificationCode(event.target.value);
  };

  const submitCode = async () => {
    const response = await verifyPhoneNumber(verificationCode);

    if (response.phoneNumberVerified) {
      setShowVerification(false);
      setShowVerificationError(false);
      dispatch(updatePhoneState(response.phoneNumberVerified));
      setVerificationCode('');
    } else setShowVerificationError(true);
  };

  const deleteNumber = async () => {
    const response = await deletePhoneNumber();
    if (response.phoneNumber == null) {
      localStorage.setItem('phoneNumber', 'null');
      setSavedPhoneNumber('null');
      setShowVerification(false);
      setShowVerificationError(false);
      setShowAddNumberError(false);
      setPhoneNumber('');
      setVerificationCode('');
      dispatch(updatePhoneState(false));
    }
  };
  return (
    <>
      {savedPhoneNumber !== 'null' && phoneVerified
        ? (
          <div className="add-phone">
            <h4 className="black">
              {`Saved phone number : ${hiddenNumber}`}
            </h4>
            <button className="primary-btn ml-05 long" onClick={deleteNumber}>Delete Phone Number</button>
          </div>
        )
        : (
          <div className="add-phone">
            {showVerification && savedPhoneNumber !== null
              ? (
                <>
                  {showVerifcationError
                    ? (
                      <h5 className="error">
                        <b>Error:</b>
                        {' '}
                        Wrong verification code
                      </h5>
                    )
                    : <h5 className="black mb-05">{`Enter the verification code that was sent to ${hiddenNumber}`}</h5>}
                  <input id="verification-code" className="phone-input" placeholder="Code" type="text" value={verificationCode} onChange={(event) => handleChangeVerifcationCode(event)} />
                  <div className="phone-buttons-container">
                    <button className="primary-btn" onClick={submitCode}>Verify Number</button>
                    <button className="primary-btn ml-05" onClick={deleteNumber}>Delete Number</button>
                  </div>
                </>
              )
              : (
                <div className="add-phone-container">
                  { showAddNumberError
                    ? (
                      <p className="error">
                        <b>Error:</b>
                        {' '}
                        Wrong phone number
                      </p>
                    )
                    : <h5 className="black mb-05">This phone number is used for receiving Swidget SMS alerts</h5>}
                  <div className="phone-input-container">
                    <input id="phone-number" value={phoneNumber} className="phone-input" placeholder="Phone Number" type="text" onChange={(event) => handleChangePhoneNumber(event)} />
                    <button className="primary-btn" onClick={addNumber}>Add Phone Number</button>
                  </div>
                </div>
              ) }
          </div>
        )}
    </>
  );
}

export default AddPhoneNumber;
