import React from 'react';
import { useSelector } from 'react-redux';
import AddPhoneNumber from './AddPhoneNumber';

function AccountDetails() {
  const currentUser = useSelector((state) => state.userInfo.currentUser);

  return (
    <div className="content">
      <div className="sub-header dash-tab mb-1">
        <div className="left">
          <h1>Account Details</h1>
        </div>
      </div>
      <div className="scrollable-wrapper">
        <div className="account-details-row">
          <h3 className="black">First Name</h3>
          <h4 className="black details">{currentUser?.firstName}</h4>
        </div>
        <div className="account-details-row">
          <h3 className="black">Last Name</h3>
          <h4 className="black details">{currentUser?.lastName}</h4>
        </div>
        <div className="account-details-row">
          <h3 className="black">Email</h3>
          <h4 className="black details">{currentUser?.email}</h4>
        </div>
        <div>
          <AddPhoneNumber />
        </div>
      </div>
    </div>
  );
}

export default AccountDetails;
