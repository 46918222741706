import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function ScheduleDatePicker(props) {
  return (
    <div style={{ overflowX: 'auto', marginBottom: '0.5rem' }} id="date-picker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker value={props.value ?? ''} onChange={(newValue) => props.onChange(newValue)} />
      </LocalizationProvider>
    </div>
  );
}

export default ScheduleDatePicker;
