import { Auth } from 'aws-amplify';

export const signIn = async (username, password) => {
  try {
    const response = await Auth.signIn(username, password);
    const attributes = response?.attributes;
    let mfaConfigured = false;
    let rememberDevice = false;

    // Set mfaConfigured = true if account has 2fa set up
    if (response.challengeName === 'SMS_MFA' || response.challengeName === 'SOFTWARE_TOKEN_MFA') {
      mfaConfigured = true;
      return { mfa: response, mfaConfigured, rememberDevice };
    }

    // Get credentials
    const token = response.signInUserSession.idToken.jwtToken;
    const credentials = await Auth.federatedSignIn(
      `cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`,
      { token },
    );

    /* Set mfaConfigured = true & rememberDevice = true
    if account has 2fa set up AND device is remembered */
    if (response.preferredMFA === 'SMS_MFA' || response.preferredMFA === 'SOFTWARE_TOKEN_MFA') {
      mfaConfigured = true;
      rememberDevice = true;
    }

    return {
      attributes, credentials, mfaConfigured, rememberDevice,
    };
  } catch (error) {
    return { error };
  }
};

export const confirmSignIn = async (mfa, code) => {
  try {
    const response = await Auth.confirmSignIn(mfa, code, mfa.challengeName);

    const token = response.signInUserSession.idToken.jwtToken;
    const credentials = await Auth.federatedSignIn(
      `cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`,
      { token },
    );
    return { credentials };
  } catch (error) {
    return { error };
  }
};

export const forgotPassword = async (user) => {
  try {
    const response = await Auth.forgotPassword(user);
    return response;
  } catch (error) {
    return { error };
  }
};

export const forgotPasswordSubmit = async (user, code, password) => {
  try {
    const response = await Auth.forgotPasswordSubmit(user, code, password);
    return response;
  } catch (error) {
    return { error };
  }
};

export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('ERROR');
    return { error };
  }
};

export const isLoggedIn = () => Auth.currentUserInfo().then((data) => {
  if (!data) {
    return false;
  }
  return true;
}).catch(() => false);

export const rememberDevice = async () => {
  try {
    const result = await Auth.rememberDevice();
    console.log(`Remember device: ${result}`);
  } catch (error) {
    console.log('Error remembering device', error);
  }
};

export const forgetDevice = async () => {
  try {
    const result = await Auth.forgetDevice();
    console.log(`Forget device: ${result}`);
  } catch (error) {
    console.log('Error forgetting device', error);
  }
};

export const fetchDevices = async () => {
  try {
    const result = await Auth.fetchDevices();
    console.log('List of remembered devices:');
    console.log(result);
  } catch (err) {
    console.log('Error fetching devices', err);
  }
};

export const signup = async ({
  givenName, familyName, email, password,
}) => {
  try {
    const response = await Auth.signUp({
      username: email.toLowerCase(),
      password,
      attributes: {
        givenName,
        familyName,
      },
    });
    return response;
  } catch (error) {
    return (error);
  }
};

export const confirmSignUp = async ({ username, confirmationCode }) => {
  try {
    const response = await Auth.confirmSignUp(username, confirmationCode);
    return response;
  } catch (error) {
    console.log('error confirming sign up', error);
  }
};

export const resendVerificationCode = async ({ username }) => {
  try {
    const response = await Auth.resendSignUp(username);
    return response;
  } catch (error) {
    console.log(error);
  }
};
