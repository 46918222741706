/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'userInfo',
  initialState: {
    userId: '',
    ownerId: '',
    attributes: {},
    mfaConfigured: false,
    rememberDevice: false,
    sites: [],
    selectedSiteName: '',
    selectedSiteId: '',
    selectedRoomName: '',
    selectedRoomId: '',
    selectedKitId: '',
    roomList: [],
    deviceList: [],
    automationList: [],
    eventList: [],
    activeList: [],
    editRoom: false,
    lastDownloadAt: 0,
    archivedList: [],
    unassignedKits: [],
    assignedKits: [],
    commentList: [],
    viewEventPopup: false,
    userRole: '',
    alertsOrder: '',
    users: [],
    audience: '',
    phoneVerified: false,
    currentUser: '',
    currentTab: '',
    videoInfo: {},
    commentType: '',
    devicesOrder: {},
  },
  reducers: {
    updateUserId: (state, action) => {
      state.userId = action.payload;
    },
    updateOwnerId: (state, action) => {
      state.ownerId = action.payload;
    },
    updateAttributes: (state, action) => {
      state.attributes = action.payload;
    },
    updateMfaConfigured: (state, action) => {
      state.mfaConfigured = action.payload;
    },
    updateRememberDevice: (state, action) => {
      state.rememberDevice = action.payload;
    },
    updateSitesList: (state, action) => {
      state.sites = action.payload;
    },
    updateSelectedSiteName: (state, action) => {
      state.selectedSiteName = action.payload;
    },
    updateSelectedSiteId: (state, action) => {
      state.selectedSiteId = action.payload;
    },
    updateSelectedRoomName: (state, action) => {
      state.selectedRoomName = action.payload;
    },
    updateSelectedRoomId: (state, action) => {
      state.selectedRoomId = action.payload;
    },
    updateSelectedKitId: (state, action) => {
      state.selectedKitId = action.payload;
    },
    updateRoomList: (state, action) => {
      state.roomList = action.payload;
    },
    updateDeviceList: (state, action) => {
      state.deviceList = action.payload;
    },
    updateAutomationList: (state, action) => {
      state.automationList = action.payload;
    },
    updateEventList: (state, action) => {
      state.eventList = action.payload;
    },
    updateActiveList: (state, action) => {
      state.activeList = action.payload;
    },
    updateEditRoom: (state, action) => {
      state.editRoom = action.payload;
    },
    updateLastDownloadAt: (state, action) => {
      state.lastDownloadAt = action.payload;
    },
    updateArchivedList: (state, action) => {
      state.archivedList = action.payload;
    },
    updateUnassignedKitsState: (state, action) => {
      state.unassignedKits = action.payload;
    },
    updateAssingedKitsState: (state, action) => {
      state.assignedKits = action.payload;
    },
    updateCommentType: (state, action) => {
      state.commentType= action.payload;
    },
    updateCommentList: (state, action) => {
      state.commentList = [...state.commentList, action.payload];
    },
    clearCommentList: (state) => {
      state.commentList = [];
    },
    updateViewEventPopup: (state, action) => {
      state.viewEventPopup = action.payload;
    },
    updateUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    updateAlertsOrder: (state, action) => {
      state.alertsOrder = action.payload;
    },
    updateUsers: (state, action) => {
      state.users = action.payload;
    },
    updatePhoneState: (state, action) => {
      state.phoneVerified = action.payload;
    },
    updateAudience: (state, action) => {
      state.audience = action.payload
    },
    updateCurrentUser: (state, action) => {
      state.currentUser = action.payload
    },
    updateCurrentTab: (state, action) => {
      state.currentTab = action.payload
    },
    updateVideoInfo: (state, action) => {
      state.videoInfo = action.payload
    },
    updateDevicesOrder: (state, action) => {
      state.devicesOrder = action.payload
    },
    resetState: (state, action) => {
      state.userId = action.payload;
      state.ownerId = action.payload;
      state.attributes = action.payload;
      state.mfaConfigured = action.payload;
      state.rememberDevice = action.payload;
      state.sites = action.payload;
      state.selectedSiteName = action.payload;
      state.selectedSiteId = action.payload;
      state.selectedRoomName = action.payload;
      state.selectedRoomId = action.payload;
      state.selectedKitId = action.payload;
      state.roomList = action.payload;
      state.deviceList = action.payload;
      state.automationList = action.payload;
      state.eventList = action.payload;
      state.activeList = action.payload;
      state.editRoom = false;
      state.archivedList = action.payload;
      state.unassignedKits = action.payload;
      state.assignedKits = action.payload;
      state.commentList = action.payload;
      state.viewEventPopup = action.payload;
      state.userRole = action.payload;
      state.alertsOrder = action.payload;
      state.users = action.payload;
      state.audience = action.payload;
      state.phoneVerified = false;
      state.lastDownloadAt = 0;
      state.currentUser = action.payload;
      state.currentTab = action.payload;
      state.videoInfo = {};
      state.devicesOrder = {};
      state.commentType= '';
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  resetState,
  updateUserId,
  updateOwnerId,
  updateAttributes,
  updateMfaConfigured,
  updateRememberDevice,
  updateSitesList,
  updateSelectedSiteName,
  updateSelectedSiteId,
  updateSelectedRoomName,
  updateSelectedRoomId,
  updateSelectedKitId,
  updateRoomList,
  updateDeviceList,
  updateAutomationList,
  updateEventList,
  updateActiveList,
  updateEditRoom,
  updateLastDownloadAt,
  updateArchivedList,
  updateUnassignedKitsState,
  updateAssingedKitsState,
  updateCommentList,
  clearCommentList,
  updateViewEventPopup,
  updateUserRole,
  updateAlertsOrder,
  updateUsers,
  updateAudience,
  updatePhoneState,
  updateCurrentUser,
  updateCurrentTab,
  updateVideoInfo,
  updateCommentType,
  updateDevicesOrder,
} = userSlice.actions;

export default userSlice.reducer;
