import React, { useEffect, useState } from 'react';
import Dropdown from './Dropdown';
import { formatSource } from './FormatStuff';

function EventTypeFilter(props) {
  const eventsTypesOptions = ['All', 'video', 'occupancy', 'connectionStatus', 'lock', 'water', 'waterSensor'];
  const [dropDownIndex, setDropDownIndex] = useState(0);

  useEffect(() => {
    const index = eventsTypesOptions.indexOf(props.sourceType);
    if (index > 0) setDropDownIndex(index);
    else setDropDownIndex(0);
  }, [props.sourceType]);

  const formattedEventsTypesOptions = eventsTypesOptions.map(
    (event) => formatSource(event),
  );

  const selectType = (index) => {
    props.updateSource(eventsTypesOptions[index]);
  };

  return (
    <div className="events-type-filter">
      <Dropdown options={formattedEventsTypesOptions} label="Event Type : " updateOption={selectType} firstSelectedOptionIndex={dropDownIndex} />
    </div>
  );
}

export default EventTypeFilter;
