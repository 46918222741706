import React, { useEffect, useState } from 'react';
import ScheduleDatePicker from './ScheduleDatePicker';
import ScheduleTimePicker from './ScheduleTimePicker';
import EventTypeFilter from './EventTypeFilter';
import EventsTagsFilter from './EventsTagsFilter';

function EventsFilter(props) {
  const [emptyFilter, setEmptyFilter] = useState(true);

  const clearFilter = () => {
    props.setStartDate(null);
    props.setEndDate(null);
    props.setStartTime(null);
    props.setEndTime(null);
    props.setStartDateObject(null);
    props.setEndDateObject(null);
    props.setErrorMessage('');
    props.updateSource('');
    props.setShowPopup(false);
    props.updateTag('All');
  };

  const applyFilter = () => props.setShowPopup(false);

  const handleDateChange = (value, type) => {
    if (type === 'startDate') props.setStartDate(value);
    else props.setEndDate(value);
  };

  const handleTimeChange = (value, type) => {
    if (type === 'startTime') props.setStartTime(value);
    else props.setEndTime(value);
  };

  useEffect(() => {
    if (props?.sourceType !== '' || props.startDate || props.startTime || props.endDate || props.endTime || props?.tag) {
      setEmptyFilter(false);
    }
  }, [props.sourceType, props.startDate, props.startTime, props.endDate, props.endTime, props.tag]);

  return (
    <>
      <div className="date-time-filter-container">
        <div className="events-filter">
          <EventsTagsFilter tag={props.tag} updateTag={props.updateTag} />
          <EventTypeFilter updateSource={props.updateSource} sourceType={props.sourceType} />
        </div>
        <div>
          <div className="date-time-container">
            <span className="from-to">From&nbsp;</span>
            <ScheduleDatePicker value={props.startDate} onChange={(value) => handleDateChange(value, 'startDate')} />
            <ScheduleTimePicker value={props.startTime} onChange={(value) => handleTimeChange(value, 'startTime')} />
          </div>
          <div className="date-time-container">
            <span className="from-to">To&nbsp;</span>
            <ScheduleDatePicker value={props.endDate} onChange={(value) => handleDateChange(value, 'endDate')} />
            <ScheduleTimePicker value={props.endTime} onChange={(value) => handleTimeChange(value, 'endTime')} />
          </div>
          <span className="error mb-05">{props.errorMessage}</span>
        </div>
      </div>
      <div className="filter-buttons">
        <button className="primary-btn clear-filter" onClick={applyFilter} disabled={emptyFilter}>Ok</button>
        <button className="primary-btn clear-filter" onClick={clearFilter} disabled={emptyFilter}>Clear Filter</button>
      </div>
    </>
  );
}

export default EventsFilter;
