/* eslint-disable */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { updateUsersInfo, customSortFunction, capitalizeFirstLetter } from './Update';
import { formatDate } from './FormatStuff';
import deleteImg from '../assets/delete.svg';
import edit from '../assets/editBlack.svg';
import Popup from './Popup';
import AddEditUserComponent from './AddEditUserComponent';
import DeleteUserComponent from './DeleteUserComponent';
import Dropdown from './Dropdown';

function UsersTab() {
  const dispatch = useDispatch();
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const users = useSelector((state) => state.userInfo.users);
  const [showPopup, setShowPopup] = useState({ add: false, edit: false, delete: false });
  const [selectedUser, setSelectedUser] = useState({});
  const mainAlertOptions = ['Date Added', 'Access Level', 'Alphabetical'];
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [usersSortedByDate, setUsersSortedByDate] = useState([]);
  const [usersSortedByRole, setUsersSortedByRole] = useState([]);
  const [usersSortedByName, setUsersSortedByName] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const sites = useSelector((state) => state.userInfo.sites);
  const selectedSite = sites ? sites.find((site) => site.siteId === selectedSiteId) : {};
  const timeZone = selectedSite?.config?.tz?.id;

  const getCurrentUsers = async () => {
    await updateUsersInfo(dispatch, selectedSiteId);
  };

  const updateSortOption = (index) => {
    setSelectedOptionIndex(index);
  };

  useEffect(() => {
    getCurrentUsers();
  }, []);

  useEffect(() => {
    users && users.length > 1
      ? setUsersSortedByDate(users.slice().sort(
        (firstElement, secondElement) => firstElement.createdAt - secondElement.createdAt,
      ))
      : setUsersSortedByDate([]);
    users && users.length > 1
      ? setUsersSortedByRole(users.slice().sort(
        (firstElement, secondElement) => firstElement.role.localeCompare(
          secondElement.role,
        ),
      ))
      : setUsersSortedByRole([]);
    users && users.length > 1
      ? setUsersSortedByName(users.slice().sort((firstElement, secondElement) => customSortFunction(firstElement.invitee.firstName, secondElement.invitee.firstName, 'asc')))
      : setUsersSortedByName([]);
  }, [users]);

  useEffect(() => {
    selectedOptionIndex === 0
      ? setDisplayedUsers(usersSortedByDate)
      : (selectedOptionIndex === 1 ? setDisplayedUsers(usersSortedByRole)
        : setDisplayedUsers(usersSortedByName));
  }, [users, selectedOptionIndex, usersSortedByDate, usersSortedByRole]);

  return (
    <div className="content">
      <div className="sub-header dash-tab mb-1">
        <div className="left">
          <h1>Users</h1>
        </div>
        <div className="right">
          <Dropdown label="Sort by: " options={mainAlertOptions} updateOption={updateSortOption} />
        </div>
      </div>

      {displayedUsers.length > 0
        ? (
          <div className="scrollable-wrapper">
            <table>
              <thead>
                <tr key="row-1">
                  <th key="col1">User Name</th>
                  <th key="col2">Access Level</th>
                  <th key="col3">Status</th>
                  <th key="col4">Date Added</th>
                  <th key="col5" />
                </tr>
              </thead>
              <tbody>
                {displayedUsers.map((user) => (
                  <tr key={user.invitee.inviteeId} className="white" onClick={() => setSelectedUser(user)}>
                    <td>{`${user.invitee.firstName} ${user.invitee.lastName}`}</td>
                    <td>{capitalizeFirstLetter(user.role)}</td>
                    <td>{capitalizeFirstLetter(user.status)}</td>
                    <td>{formatDate(user.createdAt, timeZone)}</td>
                    <td className="icons-container">
                      <img src={edit} alt="edit" className="edit-btn" onClick={() => setShowPopup({ ...showPopup, edit: true })} />
                      <img src={deleteImg} alt="delete" className="user-icons" onClick={() => setShowPopup({ ...showPopup, delete: true })} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <h4 className="header-link ml-05 mb-05 " onClick={() => setShowPopup({ ...showPopup, add: true })}>+Add another user</h4>
            {showPopup.edit && <Popup closePopup={() => setShowPopup({ ...showPopup, edit: false })} title="Edit User" child={<AddEditUserComponent edit user={selectedUser} close={setShowPopup} />} />}
            {showPopup.add && <Popup closePopup={() => setShowPopup({ ...showPopup, add: false })} title="Add New User" child={<AddEditUserComponent edit={false} close={setShowPopup} />} />}
            {showPopup.delete && <Popup closePopup={() => setShowPopup({ ...showPopup, delete: false })} title="Delete User" child={<DeleteUserComponent user={selectedUser} close={setShowPopup} />} />}
          </div>
        )
        : (
          <div className="white-wrapper">
            <p>No Users</p>
          </div>
        ) }
    </div>
  );
}

export default UsersTab;
