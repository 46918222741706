import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { postEventComment, postPropertyComment } from '../api/UserAPI';
import close from '../assets/close.svg';
import send from '../assets/send.svg';
import { clearCommentList, updateCommentList } from '../redux/userInfo';
import { updateAlertsIncrementally, updateEventsIncrementally, updateRooms } from './Update';

function CommentPopup(props) {
  const { selectedEvent } = props;

  // site info
  const dispatch = useDispatch();
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const commentList = useSelector((state) => state.userInfo.commentList);
  const alertsOrder = useSelector((state) => state.userInfo.alertsOrder);
  const selectedRoomId = useSelector((state) => state.userInfo.selectedRoomId);
  const commentType = useSelector((state) => state.userInfo.commentType);

  const addComment = async () => {
    const message = document.getElementById('comment-input')?.value;
    if (commentType === 'property') {
      const { roomId } = props.selectedProperty;
      const response = await postPropertyComment(selectedSiteId, roomId, message);
      dispatch(updateCommentList(response?.room?.comments[0]));
      await updateRooms(dispatch, selectedSiteId);
    } else {
      const eventId = selectedEvent?.eventId;
      const response = await postEventComment(selectedSiteId, eventId, message);
      dispatch(updateCommentList(response?.comment));
      if (props.selectedOptionLogIndex !== undefined) await updateEventsIncrementally(dispatch, selectedSiteId, selectedRoomId, 25, [], [], props.startTime, props.endTime, props.sourceType, props.selectedOptionLogIndex === 0 ? 'des' : 'asc');
      else await updateAlertsIncrementally(dispatch, selectedSiteId, null, 25, [], [], alertsOrder);
    }
  };

  const closePopup = () => {
    props.closePopup();
    dispatch(clearCommentList());
  };

  return (
    <div id="comment-popup" className="popup">
      <div className="popup-content">

        <h2 className="black mb-2">{`${commentType === 'property' ? props.selectedProperty.name : selectedEvent?.content?.roomName}`}</h2>
        <img src={close} alt="close" className="close-btn" onClick={() => closePopup()} />

        <div className="input-wrapper">
          <input type="text" id="comment-input" placeholder="Write a comment" autoFocus />
          <button onClick={() => addComment()}><img src={send} id="send-btn" alt="send" /></button>
        </div>

        {commentList?.length
          ? (
            <table>
              <thead>
                <tr>
                  <th colSpan="2">Comment</th>
                </tr>
              </thead>
              <tbody>
                {commentList?.map((comment) => (
                  <tr key={comment?.commentedAt}>
                    <td>
                      <div className="bold mb-05">{comment?.commentedBy}</div>
                      {comment?.comment}
                    </td>
                    <td align="right" className="comment-time">{moment(comment?.commentedAt).format('DD MMM YYYY hh:mm a')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )
          : null}
      </div>
    </div>
  );
}

export default CommentPopup;
