import * as React from 'react';
import { useState, useEffect } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';

function ScheduleTimePicker(props) {
  const time = props.value;
  const [value, setValue] = useState(null);
  useEffect(() => {
    setValue(moment(time, 'HH:mm'));
  }, [time]);
  return (
    <div style={{ overflowX: 'auto' }} id="time-picker">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TimePicker value={value} onChange={(newValue) => props.onChange(newValue)} ampm={false} />
      </LocalizationProvider>
    </div>
  );
}

export default ScheduleTimePicker;
