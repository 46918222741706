/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useCallback } from 'react';
import { Backdrop } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TabsHeader from './TabsHeader';
import { updateAlertsIncrementally, getAlertsCount, updateEventsIncrementally } from './Update';
import { updateAlertsOrder, updateSelectedRoomId, updateSelectedRoomName } from '../redux/userInfo';
import AutomationsTab from './AutomationsTab';
import SupportTab from './SupportTab';
import AddressTab from './AddressTab';
import ActivityTab from './ActivityTab';

function PropertyDetails(props) {
  const dispatch = useDispatch();
  const [isAlertLoading, setAlertLoading] = useState(false);
  const [isEventLoading, setEventLoading] = useState(false);
  const [activeAlertsCount, setActiveAlertsCount] = useState(0);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const activeList = useSelector((state) => state.userInfo.activeList);
  const alertsOrder = useSelector((state) => state.userInfo.alertsOrder);
  const [selectedEvent, setSelectedEvent] = useState({});
  const roomList = useSelector((state) => state.userInfo.roomList);
  const [selectedOptionLogIndex, setSelectedOptionLogIndex] = useState(0);
  const [logFilterStartTime, setLogfilterStartTime] = useState('');
  const [logFilterEndTime, setLogFilterEndTime] = useState('');
  const [sourceType, setSourceType] = useState('');

  const { roomId: selectedRoomId } = useParams();

  const eventUpdater = useCallback(async () => {
    setAlertLoading(true);
    setEventLoading(true);
    try {
      props.chosenTab !== 'activtyLog' ?? dispatch(updateAlertsOrder('desc'));
      await updateEventsIncrementally(dispatch, selectedSiteId, selectedRoomId, 25, [], [], logFilterStartTime, logFilterEndTime, sourceType, selectedOptionLogIndex === 0 ? 'desc' : 'asc');
      await updateAlertsIncrementally(
        dispatch,
        selectedSiteId,
        selectedRoomId,
        25,
        [],
        [],
        alertsOrder,
      );
      const activeAlertCount = await getAlertsCount(selectedSiteId, selectedRoomId);
      setActiveAlertsCount(activeAlertCount);
    } catch (err) {
      console.log(err);
    }
    setAlertLoading(false);
    setEventLoading(false);
  }, [dispatch, selectedSiteId, selectedRoomId, selectedOptionLogIndex, alertsOrder]);

  // run only once when component mounts
  useEffect(() => {
    const found = roomList?.rooms?.find((room) => room.roomId === selectedRoomId);
    dispatch(updateSelectedRoomId(selectedRoomId));
    dispatch(updateSelectedRoomName(found?.name));
    eventUpdater();
  }, [dispatch, selectedRoomId, roomList?.rooms]);

  useEffect(() => {
    eventUpdater();
  }, [eventUpdater]);

  useEffect(() => {
    async function udpateCount() {
      const activeAlertCount = await getAlertsCount(selectedSiteId, selectedRoomId);
      setActiveAlertsCount(activeAlertCount);
    }
    udpateCount();
  }, [activeList]);

  return (
    <div className="right-container">

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isAlertLoading || isEventLoading}
      />
      <TabsHeader selectedEvent={selectedEvent} activeAlertsCount={activeAlertsCount} activityLog={props.chosenTab === 'activityLog'} selectedOptionLogIndex={selectedOptionLogIndex} />
      { props.chosenTab === 'automation' && <AutomationsTab setAlertLoading={setAlertLoading} setSelectedEvent={setSelectedEvent} />}
      { props.chosenTab === 'address' && <AddressTab setAlertLoading={setAlertLoading} setSelectedEvent={setSelectedEvent} />}
      { props.chosenTab === 'support' && <SupportTab setAlertLoading={setAlertLoading} setSelectedEvent={setSelectedEvent} showAlerts />}
      { props.chosenTab === 'activityLog'
      && (
      <ActivityTab
        activeAlerts={activeList}
        setSelectedEvent={setSelectedEvent}
        activeAlertsCount={activeAlertsCount}
        selectedOptionLogIndex={selectedOptionLogIndex}
        setSelectedOptionLogIndex={setSelectedOptionLogIndex}
        setEventLoading={setEventLoading}
        setAlertLoading={setAlertLoading}
        isAlertLoading={isAlertLoading}
        isEventLoading={isEventLoading}
        setLogFilterStartTime={setLogfilterStartTime}
        setLogFilterEndTime={setLogFilterEndTime}
        setSourceType={setSourceType}
        sourceType={sourceType}
      />
      )}
    </div>
  );
}

export default PropertyDetails;
